import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function SiteSettings() {

  const location = useLocation()
  const navigate = useNavigate()

  const saveChanges = () => {
    
  }

  return (
    <div className='d-flex flex-column gap-2'>
      { location?.state?.from?.title && <div>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
        <div className='d-flex align-items-center'>
          <div className='h1'>Site Settings</div>
          <button onClick={saveChanges} className='ms-auto btn btn-success'>Save Changes</button>
        </div>
        <div className='form-group'>
          <label>Terms of Service</label>
          <textarea className='form-control bg-fore text-light border-0' name="terms" id="terms" cols="30" rows="8"></textarea>
        </div>
        <div className='form-group'>
          <label>Privacy Policy</label>
          <textarea className='form-control bg-fore text-light border-0' name="privacyPolicy" id="privacyPolicy" cols="30" rows="8"></textarea>
        </div>
        <button onClick={saveChanges} className='ms-auto btn btn-success'>Save Changes</button>
    </div>
  )
}

export default SiteSettings