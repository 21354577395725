import React, { useCallback, useState } from 'react'
import { apiPost } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import { useNavigate, Link } from 'react-router-dom'
import { Collapse, Modal } from 'react-bootstrap'

function Users() {
  
  const navigate = useNavigate()

  const [signUpCodes, setSignUpCodes] = useState([])
  const [show, setShow] = useState(true)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [codeToEmail, setCodeToEmail] = useState("")
  const [emailToSendTo, setEmailToSendTo] = useState("")

  const getSignUpCode = () => {
    apiPost('admin/new-signup-code').then((response) => {
      if (response.status === 200) {
        setSignUpCodes([response.data, ...signUpCodes])
      }
    })
  }

  const copy = (value) => {
    if (navigator.clipboard && window.isSecureContext)
      navigator.clipboard.writeText(value).then(() => {
        toast.success(`Copied '${value}'!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: value
        })
      }, err => {
        alert("Could not copy, please try using keyboard shortcuts")
      })
    else {
      const textArea = document.createElement("textarea")
      textArea.value = value
      textArea.style.position = "absolute"
      textArea.style.left = "-999999px"
      document.body.prepend(textArea)
      textArea.select()

      try {
        document.execCommand('copy')
      } catch (error) {
        console.error(error)
      } finally {
        textArea.remove()
      }
    }
  }

  const sendCode = useCallback(() => {
    let body = {
      recipient: emailToSendTo,
      code: codeToEmail
    }
    console.log(body)
    apiPost("admin/send-signup-code", body).then((response) => {
      if (response.status === 200 && response.data.info.accepted.length > 0) {
        toast.success(`Invite sent!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false
        })
      }
    })
    closeEmailModal()
  }, [emailToSendTo, codeToEmail])

  const showEmailModalWithCode = (code) => {
    setCodeToEmail(code)
    setShowEmailModal(true)
  }

  const closeEmailModal = () => {
    setEmailToSendTo("")
    setCodeToEmail("")
    setShowEmailModal(false)
  }

  const signUpCodeList = () => (
    <div className='p-3 rounded-3 bg-fore mt-2'>
      <p className='display-8'>Signup Codes</p>
      <div className='d-flex flex-wrap'>
          { signUpCodes.map((value) => (
            <div key={value} className='p-3 m-1 shadow rounded-3 border border-mids-mutts'>
              <div key={value} className='d-flex align-items-center'>
                <div className='lead border px-2 py-1 border-success rounded-2 mr-2'>{value}</div>
                <div className='d-flex ml-auto'>
                  <button className='btn btn-outline-mids-mutts mr-2' onClick={() => copy(value)} data-tooltip-place="bottom" data-tooltip-id="copy-tooltip" data-tooltip-content="Copy"><i className="fa-regular fa-copy"></i></button>
                  <Tooltip id='copy-tooltip' />
                  <button className='btn btn-outline-mids-mutts' onClick={() => showEmailModalWithCode(value)} data-tooltip-place="bottom" data-tooltip-id="email-tooltip" data-tooltip-content="Email"><i className="fa-regular fa-envelope"></i></button>
                  <Tooltip id='email-tooltip' />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )

  const emailCodeModal = (
    <Modal show={showEmailModal} onHide={closeEmailModal} contentClassName='bg-background text-light montserrat'>
      <Modal.Header closeButton closeVariant='white' className='border-mids-mutts'>
        <Modal.Title id="contained-modal-title-vcenter">
          Send Signup Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Enter email to invite user</label>
          <input className='form-control bg-fore text-light border-0' type="email" value={emailToSendTo} onChange={(e) => setEmailToSendTo(e.target.value)} />
        </div>
      </Modal.Body>
      <Modal.Footer className='border-mids-mutts'>
        <div className='flex-fill d-flex flex-column gap-3'>
          <div className='ms-auto d-flex gap-3'>
            <button className='btn btn-success' onClick={() => sendCode()}>Send Invite</button>
            <button className='btn btn-danger' onClick={closeEmailModal}>Cancel</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )

  return (
    <React.Fragment>
      <div className='d-flex flex-wrap align-items-center pb-3'>
        <div className='display-6 me-2'>Users</div>
        <button className='btn btn-dark border-0 text-mids-mutts' onClick={() => setShow(!show)}>
          <i className={show ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
        </button>
      </div>
      <Collapse in={show}>
          <div className='container'>
            <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 p-2'>
              <div className='col p-1'>
                <button className='btn btn-block btn-outline-mids-mutts' onClick={getSignUpCode}>Generate Signup Code</button>
              </div>
              <div className='col p-1'>
                <Link className='btn btn-block btn-outline-mids-mutts' to={{pathname: '/admin/users'}} state={{from: {title: 'Admin'}}}>Manage Users</Link>
              </div>
            </div>  
            { signUpCodes.length > 0 &&
              signUpCodeList()
            }
          </div>
      </Collapse>
      {emailCodeModal}
    </React.Fragment>
  )
}

export default Users