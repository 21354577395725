import React, { useCallback, useContext, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { apiGet, apiPost } from '../../helpers/NetworkHelper'
import { Modal, Button } from 'react-bootstrap'
import { AuthContext } from '../../helpers/AuthContext'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function PetBookingRequestModal({show, setShow, selectedBookingSlot, refresh}) {

  const navigate = useNavigate()

  const {loggedInUser} = useContext(AuthContext)
  const [waiting, setWaiting] = useState(false)

  const [selectedPet, setSelectedPet] = useState(null)
  const [pets, setPets] = useState([])

  const book = () => {
    if (!waiting) {
      if (selectedPet) {
        setWaiting(true)
        apiPost('bookings/request', {
          bookingSlotId: selectedBookingSlot._id,
          petId: selectedPet._id
        }).then((response) => {
          setWaiting(false)
          setSelectedPet(null)
          if (response.status === 200) {
            setShow(false)
            if (refresh)
              refresh()
          } if (response.status >= 400)
            toast.error(response.data.error, {
              theme: 'dark',
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
              pauseOnFocusLoss: false
            })
        })
      } else {
        toast('No pet selected', {
          theme: 'dark',
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false
        })
      }
    }
  }

  const getPets = useCallback(() => {
    apiGet("pets", {
      username: loggedInUser.username
    }).then((response) => {
      if (response.status === 200) {
        setPets(response.data)
      } else
        setPets([])
    })
  }, [setPets, loggedInUser])

  const handleClose = () => setShow(false)

  const goToPetForm = () => {
    navigate("/new-pet")
  }

  const petsList = () => {
    const petList = []
    for (let i = 0; i < pets.length; i++) {
      let pet = pets[i]
      petList.push((
        <div key={pet._id} className='w-md-50 w-100'>
          <div onClick={() => {
            setSelectedPet(selectedPet?._id === pet._id ? null : pet)
          }} className={'bg-fore rounded-3 d-flex flex-row align-items-center hoverable clickable border ' + (selectedPet?._id === pet._id ? "border-mids-mutts" : "border-fore")}>
            <img className='img-responsive rounded-start-3' style={{width: "30%"}} alt='' src={pet.imageKitUrl} />
            <div className='fs-5 text-center flex-fill'>{pet.name}</div>
          </div>
        </div>
      ))
    }

    return (
      <React.Fragment>
        {pets.length > 0 ? <div className='d-flex'>
          {petList} 
        </div> : 
        <div className='text-center'>
          <div className='mb-3'>To make a booking you need to add a pet</div>
          <button onClick={goToPetForm} className='btn btn-outline-mids-mutts mx-auto'>Add Pet</button>
        </div>  }
      </React.Fragment>
    )
  }

  const onShow = () => {
    if (pets.length === 0)
      getPets()
  }

  return (
    <Modal onShow={onShow} show={show} onHide={handleClose} centered contentClassName='bg-background text-light montserrat'>
      <Modal.Header closeButton closeVariant='white'>
        <Modal.Title className='display-7'>Pick a Pet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {petsList()}
      </Modal.Body>
      <Modal.Footer>
        { pets.length > 0 &&
        <Button variant="mids-mutts" className='btn-block' onClick={book}>
          { waiting ? 
          < ClipLoader color='white' />
          :
          <span>Request Booking</span>}
        </Button> }
        <Button variant="secondary" className='btn-block' onClick={() => {
          setSelectedPet(null)
          handleClose()
        }}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PetBookingRequestModal