import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiDelete, apiGet } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import BookingSlotTile from './BookingSlotTile'
import { ClipLoader } from "react-spinners"
import { Collapse } from 'react-bootstrap'

function BookingSlots({availableOnly, tense, admin, collapsable}) {
  const navigate = useNavigate()

  if (!tense)
    tense = 'future'

  const [bookingSlots, setBookingSlots] = useState([])
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(true || !collapsable)
  const [pages, setPages] = useState(0)

  const getBookingSlots = useCallback(() => {
    setLoading(true)
    apiGet("bookings/slots", {pageSize: 4, page: 0, tense, availableOnly}).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setBookingSlots(response.data.slots)
        setPages(response.data.pages)
      } else if (admin && response.status === 401)
        navigate("/")
      else 
        setBookingSlots([])
    })
  }, [setBookingSlots, availableOnly, navigate, admin])

  const deleteBookingSlot = (id) => {
    const bookingSlot = bookingSlots.find((value) => value._id === id)
    const index = bookingSlots.indexOf(bookingSlot)
    apiDelete('admin/bookings/slots', bookingSlot._id).then((response) => {
      if (response.status === 200) {
        toast('Deleted Slot', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Deleted_Slot_${bookingSlot._id}_Toast`
        })
        apiGet("bookings/slots", {pageSize: 4, page: 0, tense: tense}).then((response) => {
          if (response.status === 200) 
            setBookingSlots(response.data)
          else 
            setBookingSlots(bookingSlots.splice(index, 1))
        })
      }
    })
  }

  const getTitle = () => {
    switch(tense) {
      case 'future': 
        return 'Upcoming Slots'
      case 'past': 
        return 'Past Slots'
      default:
        return 'Slots'
    }
  }

  useEffect(() => {
    getBookingSlots()
  }, [getBookingSlots])

  return (
    <div className='container py-2 bg-fore rounded-3'>
      <div className='d-flex flex-wrap align-items-center p-2'>
        <div className='display-8 me-2'>{getTitle()}</div>
        { collapsable && 
          <button className='btn btn-dark border-0 text-mids-mutts' onClick={() => setShow(!show)}>
            <i className={show ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
          </button>
        }
      </div>
      <Collapse in={show}>
        <div>
          <div className='container'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 bg-fore rounded-3 p-2'>
              { (bookingSlots.length === 0 && !loading) && <p className='col-xl-12 col-lg-12 col-md-12 col-12 display-8 p-2 mb-1 text-subtle text-center'>No results</p> }
              { loading ? 
                  <div className='col-xl-12 col-lg-12 col-md-12 col-12 d-flex pt-2'>
                    <ClipLoader className='mx-auto' color="orange" />
                  </div>
                  : 
                bookingSlots.map((slot, index) => (
                  <BookingSlotTile key={slot._id} slot={slot} deleteBookingSlot={deleteBookingSlot} admin={admin} refresh={getBookingSlots} />
                ))}
              { pages > 1 && 
                <div className='col-xl-12 col-lg-12 col-md-12 col-12 p-2 mt-1 d-flex'>
                  <div className='link-mids-mutts clickable ml-auto' onClick={() => navigate('/booking-slots')}><u>View All Slots</u></div>
                </div>
              }
            </div>
          </div>
        </div>
      </Collapse>

    </div>
  )
}

export default BookingSlots