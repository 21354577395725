import React, { useContext, useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../helpers/AuthContext'
import { apiPost, getCsrfToken } from '../helpers/NetworkHelper'
import * as Yup from "yup"

function Login() {

  const {setLoggedInUser} = useContext(AuthContext)
  const [showPasswordType, setShowPasswordType] = useState("password")
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const initialValues = {
    username: "",
    password: ""
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("is required"),
    password: Yup.string().required("is required"),
  })

  const login = (data) => {
    apiPost("auth/login", {
      username: data.username.trim(),
      password: data.password.trim()
    }).then((response) => {
      if (response.status !== 200) {
        setError(response.data.error)
        setLoggedInUser(null)
      } else {
        setError(null)
        setLoggedInUser(response.data)
        navigate("/")
      }
    })
  }

  const toggleShowPassword = () => {
    if (showPasswordType === "password")
      setShowPasswordType("text")
    else
      setShowPasswordType("password")
  }

  useEffect(() => {
    getCsrfToken()
  }, [])

  return (
    <div className='row flex flex-column align-items-center'>
      <div className='col-lg-4 col-md-6 col-sm-10 col-12'>
        <Formik initialValues={initialValues} onSubmit={login} validationSchema={validationSchema}>
          <Form className='loginContainer'>
            <div className='form-group'>
              <label>Username: </label>
              <ErrorMessage className='small text-danger ml-2'  name='username' component="span" />
              <Field className="form-control bg-fore text-light border-0" id="username" name="username" />
            </div>
            <div className='form-group'>
              <label>Password: </label>
              <ErrorMessage className='small text-danger ml-2'  name='password' component="span" />
              <div className="input-group mb-3">
                <Field type={showPasswordType} id="password" name="password" className="form-control bg-fore text-light border-0"/>
                <div className="input-group-append">
                  <button onClick={toggleShowPassword} className="btn btn-fore" type="button"><i className="fa-regular fa-eye"></i></button>
                </div>
              </div>
              <i className="bi bi-eye-slash" id="togglePassword"></i>
            </div>
            { error && 
              <div className='text-danger'>
                {error}
              </div>
            }
            <button className='btn btn-mids-mutts btn-block mt-4' type='submit'>Login</button>
          </Form>
        </Formik>
      </div>
      <div className='col-12 d-flex flex-column align-items-center'>
        {/* <a href='/forgotPassword' onClick={() => navigate("/forgotPassword")} className='small text-light mt-3'>Forgot Password?</a> */}
        <a href='/registration' onClick={() => navigate("/registration")} className='small text-light mt-3'>Need an account?</a>
      </div>
    </div>
  )
}

export default Login