import axios from 'axios'

const apiGet = (action, params = {}) => {
  return axios.get('/api/' + action, {
    validateStatus: (status) => {
      return true
    },
    params: params ?? {}
  })
} 

const apiDelete = (action, id) => {
  return axios.delete('/api/' + action, {
    params: {
      id: id
    },
    validateStatus: (status) => {
      return true
    }
  })
} 

const apiPost = (action, body) => {
  return axios.post('/api/' + action, body, {
    validateStatus: (status) => {
      return true
    }
  })
}

const apiPostFormData = (action, body) => {
  return axios.post('/api/' + action, body, {
    validateStatus: (status) => {
      return true
    },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const getCsrfToken = async (origin) => {
  apiGet("csrf-token").then((response) => {
    axios.defaults.headers.post['X-CSRF-Token'] = response.data.csrfToken
    axios.defaults.headers.delete['X-CSRF-Token'] = response.data.csrfToken
  })
}

export {apiGet, apiPost, getCsrfToken, apiDelete, apiPostFormData}