import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/Login";
import Registration from "../pages/Registration";
import PetForm from "../pages/PetForm";
import Pet from "../pages/Pet";
import Profile from "../pages/Profile";
import Bookings from "../pages/Bookings";
import Admin from "../pages/Admin";
import BookingSlotForm from "../pages/admin/BookingSlotForm";
import BookingSlots from "../pages/BookingSlots";
import BookingTypeForm from "../pages/admin/BookingTypeForm";
import BookingTypes from "../pages/admin/BookingTypes";
import Users from "../pages/admin/Users";
import ManageUser from "../pages/admin/ManageUser";
import BookingSlot from "../pages/admin/BookingSlot";
import SiteSettings from "../pages/admin/SiteSettings";
import ShopItems from "../pages/shop/ShopItems";
import ShopItemForm from "../pages/shop/ShopItemForm";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path='/registration' element={<Registration />} /> */}
      <Route path="/new-pet" element={<PetForm />} />
      <Route path="/edit-pet" element={<PetForm />} />
      <Route path="/pet" element={<Pet />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/bookings" element={<Bookings />} />
      <Route path="/booking-slots/" element={<BookingSlots />} />
      <Route path="/booking-slot/" element={<BookingSlot />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/users" element={<Users />} />
      <Route path="/admin/users/manage" element={<ManageUser />} />
      <Route path="/admin/booking-slots/new" element={<BookingSlotForm />} />
      <Route path="/admin/booking-types/new" element={<BookingTypeForm />} />
      <Route path="/admin/booking-types/" element={<BookingTypes />} />
      <Route path="/admin/site-settings/" element={<SiteSettings />} />
      <Route path="/shop/items" element={<ShopItems />} />
      <Route path="/shop/item-form" element={<ShopItemForm />} />
    </Routes>
  );
}

export default AppRoutes;
