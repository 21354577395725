import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, Link, useNavigate, useSearchParams } from 'react-router-dom'
import { apiGet, apiPost } from '../../helpers/NetworkHelper'
import { ClipLoader } from "react-spinners"

function ManageUser() {

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const username = searchParams.get("username")

  const [user, setUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(false)

  const [roles, setRoles] = useState([])
  const [roleId, setRoleId] = useState("")

  const [permissions, setPermissions] = useState([])
  const [userPermissions, setUserPermissions] = useState([])

  const updatePermissions = (perms, userPerms) => {
    for (let i = 0; i < perms.length; i++) {
      if (userPerms.find(up => up.permissionId === perms[i]._id)) {
        perms[i].checked = true
      } else {
        perms[i].checked = false
      }
    }
    setPermissions(perms)
    setUserPermissions(userPerms)
  }

  const populateUser = useCallback((response) => {
    setUser(response.data.user)
    setRoles(response.data.roles)
    updatePermissions(response.data.permissions, response.data.userPermissions)
    setRoleId(response.data.user.role._id)
  }, [setUser, setRoles, setRoleId])

  const getManageUser = useCallback(() => {
    setLoadingUser(true)
    apiGet("admin/users/manage", {username}).then((response) => {
      setLoadingUser(false)
      if (response.status === 200) {
        populateUser(response)
      } else if (response.status === 401) {
        navigate("/")
      }
    })
  }, [username, populateUser])

  const postManageUser = useCallback(() => {
    setLoadingUser(true)
    const body = {
      roleId,
      user_username: username,
      permissionsArr: permissions
    }
    apiPost("admin/users/manage", body).then((response) => {
      setLoadingUser(false)
      if (response.status === 200) {
        populateUser(response)
      } else if (response.status === 401) {
        navigate("/")
      }
    })
  }, [roleId, username, populateUser, permissions])

  useEffect(() => {
    getManageUser()
  }, [getManageUser])

  const options = (
    roles.map((role) => (
      <option value={role._id} key={role._id}>{role.description} {role.isAdmin && "(admin)"}</option>
    ))
  )

  const handlePermissionChange = (permissionIndex, e) => {
    let newPermissions = [...permissions]
    let permission = {...newPermissions[permissionIndex]};
    permission.checked = e.target.checked;
    newPermissions[permissionIndex] = permission;
    setPermissions([...newPermissions])
  }

  const permissionsTable = (
    <div className='w-100 border-top border-grey'>
      { permissions.map((permission, index) => (
        <div key={permission._id} className='border-bottom border-grey d-flex flex-row align-items-center'>
          <div className='p-2'>
            <div className='lead'>{permission.name}</div>
            <div className='small'>{permission.description}</div>
          </div>
          <div className='ms-auto form-check'>
            <input defaultChecked={permission.checked} onChange={(e) => handlePermissionChange(index, e)} className='form-check-input' type="checkbox" name={permission._id} id={permission._id} />
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div className='d-flex flex-column gap-3 align-items-start'>
      { location?.state?.from?.title && <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button> }
      <div className='display-7'>Managing User: {user && user.username}</div>
      { loadingUser ? 
        <div className='d-flex pt-2 w-100'>
          <ClipLoader className='mx-auto' color="orange" />
        </div>
      : 
        <div className='d-flex flex-column rounded-3 bg-fore p-3 w-100 gap-3'>
          <div className='d-flex flex-row flex-wrap gap-2'>
            { roleId && <div className='d-flex flex-row align-items-center w-lg-25 w-md-50 w-100'>
              <div className='lead'>Role:</div>
              <select defaultValue={roleId} className='ms-2 form-select bg-background text-light border-0' onChange={(e) => setRoleId(e.target.value)}>
                {options}
              </select>
            </div> }
            <button className='ms-auto btn btn-outline-mids-mutts align-self-end' onClick={postManageUser}>Save Changes</button>
          </div>
          { (user && user.role.isAdmin) &&
            <React.Fragment>
              <div className='display-8 d-flex align-items-end px-1'>Permissions <span className='ms-auto lead'>Granted</span></div>
              {permissionsTable}
              <button className='btn btn-outline-mids-mutts align-self-end' onClick={postManageUser}>Save Changes</button>
            </React.Fragment>
          }
        </div>
      }
    </div>
  )
}

export default ManageUser