import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import { apiGet, apiPost, getCsrfToken } from '../../helpers/NetworkHelper'
import moment from 'moment'
import { ClipLoader } from "react-spinners"

const getHourDiff = (a, b) => {
  let start = moment(a)
  let end = moment(b)
  return Math.floor(moment.duration(end.diff(start)).asHours())
}

const getMinuteDiff = (a, b) => {
  let start = moment(a)
  let end = moment(b)
  return Math.floor(moment.duration(end.diff(start)).asMinutes() % 60)
}

function BookingSlotForm() {

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const [error, setError] = useState(null)
  const [bookingTypes, setBookingTypes] = useState([])
  const [typesLoaded, setTypesLoaded] = useState(false)
  const [loaded, setLoaded] = useState(id ? false : true)
  
  const [values, setValues] = useState({sameDay: true})

  const onSubmit = (data) => {
    apiPost("admin/bookings/slots", values).then((response) => {
      if (response.status === 200)
        navigate('/booking-slots')
      else {
        setError(response.data.error)
      }
    })
  }

  const getBookingSlot = useCallback(() => {
    setLoaded(false)
    apiGet("admin/bookings/slot", {id: id}).then((response) => {
      setLoaded(true)
      if (response.status === 200) {
        let slot = response.data.slot
        setValues({
          ...values, 
          typeId: slot.bookingType._id, 
          spaces: slot.spaces,
          cost: slot.cost,
          startdate: moment(slot.start).format("yyyy-MM-DD"),
          starttime: moment(slot.start).format("HH:mm"),
          hours: getHourDiff(slot.start, slot.end),
          minutes: getMinuteDiff(slot.start, slot.end),
          enddate: moment(slot.end).format("yyyy-MM-DD"),
          endtime: moment(slot.end).format("HH:mm"),
          info: slot.info,
          sameDay: moment(slot.start).day === moment(slot.end).day,
          id: slot._id,
        })
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching booking slots!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-slots-error"
        })
    })
  }, [setValues, navigate, id])

  useEffect(() => {
    const getBookingTypes = () => {
      apiGet("bookings/types").then((response) => {
        setTypesLoaded(true)
        if (response.status === 200) {
          setBookingTypes(response.data.types)
          if (response.data.types.length > 0 && !values._id)
            setValues({...values, typeId: response.data.types[0]._id})
          if (id)
            getBookingSlot()
        } else if (response.status === 401) {
          navigate("/")
        } else {
          setBookingTypes([])
        }
      })
    }
    getBookingTypes()
    getCsrfToken()
  }, [navigate, id, getBookingSlot])

  const bookingTypeOptions = (
    <React.Fragment>
      { bookingTypes.map((value, index) => {
        return <option key={value._id} value={value._id}>{value.name}</option>
      })}
    </React.Fragment>
  )

  const getCostFromType = (typeId) => {
    const bookingType = bookingTypes.find((value) => value._id === typeId)
    if (bookingType && bookingType.cost)
      return bookingType.cost ?? ""
    return ""
  }

  return (
    <div className='row'>
      <div className='d-flex flex-column col-md-8 offset-md-2'>
      { location?.state?.from?.title && <div className='mb-2'>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
        <div className='display-8 mb-3'>{ id ? "Booking Slot" : "New Booking Slot"}</div>
        { (loaded && typesLoaded && bookingTypes.length > 0) ? 
        <Formik initialValues={{}} onSubmit={onSubmit}>
          <Form className='loginContainer mb-3'>
            <div className='row row-cols-md-2 row-cols-1'>
              <div className='form-group'>
                <label>Type</label>
                <ErrorMessage className='small text-danger ml-2'  name='type' component="span" />
                <select className="form-select bg-fore text-light border-0" value={values.typeId} name='type' onChange={(event) => {
                  setValues({...values, typeId: event.target.value, cost: getCostFromType(event.target.value)})
                }}>
                  {bookingTypeOptions}
                </select>
              </div>
              <div className='form-group'>
                <label>Spaces</label>
                <ErrorMessage className='small text-danger ml-2'  name='cost' component="span" />
                <Field type="number" step="1" className="form-control bg-fore text-light border-0" value={values.spaces} name="spaces" onChange={(event) => {
                  setValues({...values, spaces: event.target.value})
                }} />
              </div>
              <div className='form-group'>
                <label>Start</label>
                <ErrorMessage className='small text-danger ml-2'  name='start' component="span" />
                <div className="input-group mb-3">
                  <span className="input-group-text bg-secondary text-light border-0">Date</span>
                  <Field type="date" value={values.startdate} onChange={(event) => setValues({...values, startdate: event.target.value})} className="form-control bg-fore text-light border-0" name="startdate" />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text bg-secondary text-light border-0">Time</span>
                  <Field type="time" value={values.starttime} onChange={(event) => setValues({...values, starttime: event.target.value})} className="form-control bg-fore text-light border-0" name="starttime" />
                </div>
              </div>
              { values.sameDay ? 
              <div className='form-group'>
                <div className='d-flex align-items-center'>
                  <div>Duration</div>
                  <div className='ms-auto btn btn-sm btn-link' onClick={() => setValues({...values, sameDay: false})}>Set end date instead?</div>
                </div>
                <ErrorMessage className='small text-danger ml-2'  name='duration' component="span" />
                <div className="input-group mb-3">
                  <span className="input-group-text bg-secondary text-light border-0">Hours</span>
                  <Field type="number" value={values.hours} onChange={(event) => setValues({...values, hours: event.target.value})} className="form-control bg-fore text-light border-0" name="hours" />
                  <span className="input-group-text bg-secondary text-light border-0">Minutes</span>
                  <Field type="number" value={values.minutes} onChange={(event) => setValues({...values, minutes: event.target.value})} className="form-control bg-fore text-light border-0" name="minutes" />
                </div>
              </div>
              :
              <div className='form-group'>
                <div className='d-flex align-items-center'>
                  <div>End</div>
                  <div className='ms-auto btn btn-sm btn-link' onClick={() => setValues({...values, sameDay: true})}>Set duration instead?</div>
                </div>
                <ErrorMessage className='small text-danger ml-2'  name='end' component="span" />
                <div className="input-group mb-3">
                  <span className="input-group-text bg-secondary text-light border-0">Date</span>
                  <Field type="date" value={values.enddate} onChange={(event) => setValues({...values, enddate: event.target.value})} className="form-control bg-fore text-light border-0" name="enddate" />
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text bg-secondary text-light border-0">Time</span>
                  <Field type="time" value={values.endtime} onChange={(event) => setValues({...values, endtime: event.target.value})}  className="form-control bg-fore text-light border-0" name="endtime" />
                </div>
              </div>
              }
              <div className='form-group'>
                <label>Cost</label>
                <ErrorMessage className='small text-danger ml-2'  name='cost' component="span" />
                <div className="input-group mb-3">
                  <span className="input-group-text bg-secondary text-light border-0">£</span>
                  <Field type="number" value={values.cost} onChange={(event) => setValues({...values, cost: event.target.value})} className="form-control bg-fore text-light border-0" name="cost" />
                </div>
              </div>
              <div className='form-group'>
                <label>Extra Information</label>
                <ErrorMessage className='small text-danger ml-2'  name='info' component="span" />
                <Field value={values.info} onChange={(event) => setValues({...values, info: event.target.value})} className='form-control bg-fore text-light border-0' name="info" component="textarea" rows="3" />
              </div>
            </div>
            { error && 
              <div className='text-danger'>
                {error}
              </div>
            }
            <button className='btn btn-mids-mutts btn-block mt-4' type='submit'>Save Booking Slot</button>
          </Form>
        </Formik>
        : !loaded ? 
        <div className='d-flex pt-2'>
          <ClipLoader className='mx-auto' color="orange" />
        </div>
        :
        typesLoaded && 
        <div>
          <div className='lead mb-3'>Booking type required to create booking slot, but none exist yet.</div>
          <button type='submit' className='btn btn-outline-mids-mutts' onClick={() => navigate('/admin/booking-types/new')}>Create Booking Type</button>
        </div>
        }
      </div>
    </div>
  )
}

export default BookingSlotForm