import React, { useContext } from 'react'
import { formatDate, formatTime } from '../../helpers/DateTimeUtils'
import { AuthContext } from '../../helpers/AuthContext'
import { getHourDiff, getMinuteDiff } from '../../helpers/DateTimeUtils'

function BookingTile({booking, bookingSlot, acceptRequest, rejectRequest, showOwner, showPet, admin, showStatus}) {

  const {loggedInUser} = useContext(AuthContext)

  const getBookingSlot = () => {
    return bookingSlot ?? booking.bookingSlot
  }

  const getHoursLabel = (hours) => hours > 1 ? " hours" : " hour"
  const getMinutesLabel = (minutes) => minutes > 1 ? " mins" : " min"

  const getDuration = () => {
    let hours = getHourDiff(getBookingSlot().start, getBookingSlot().end)
    let minutes = getMinuteDiff(getBookingSlot().start, getBookingSlot().end)
    return `${hours > 0 ? hours + getHoursLabel(hours) : ""} ${minutes > 0 ? minutes +  getMinutesLabel(minutes) : ""}`
  }

  return (
    <div key={booking._id} className='col p-2'>
      <div className='h-100 border border-mids-mutts bg-fore rounded-3 p-3 d-flex flex-column'>
        { showPet &&
          <div className='mb-2'>
            <div className='fs-4'>{booking.pet.name}</div>
            { showOwner && <div className='d-flex'>Owner: <span className='ms-auto'>{booking.pet.owner.firstname} {booking.pet.owner.lastname}</span></div> }
          </div> }
        <div className={'rounded-2 ' + (showOwner||showPet ? "bg-background p-2" : "")}>
          { (showStatus && !booking.status !== 'Confirmed') && 
            <div className='text-center bg-background rounded-2 mb-2 text-subtle'>{booking.status}</div>
          }
          <div className='fs-5'>{getBookingSlot().bookingType.name}</div>
          <div>{getBookingSlot().info}</div>
          <div className='d-flex'>{formatDate(getBookingSlot().start)} <span className='ms-auto'>{formatTime(getBookingSlot().start)}</span></div>
          <div className='d-flex'><span className='ms-auto'>{getDuration()}</span></div>
          { getBookingSlot().info && 
            <div className='bg-background rounded-3 p-2 my-2'>{getBookingSlot().info}</div>
          }
        </div>
        { !booking.confirmed && admin ?
        <div className='mt-auto'>
          <div className='mt-3 d-flex gap-2'>
            <button className='btn btn-outline-mids-mutts flex-fill' onClick={() => acceptRequest(booking)}>Accept</button>
            <button className='btn btn-outline-danger flex-fill' onClick={() => rejectRequest(booking)}>Reject</button>
          </div> 
        </div>
        :
        <div className='mt-auto'>
          <div>
            {((admin || (loggedInUser && loggedInUser.username === booking.pet.owner.username))) && 
                <div className='mt-3 d-flex flex-column gap-2'>
            {(admin) && 
            <button className='btn btn-outline-mids-mutts flex-fill'>Manage Slot</button>}
              {((admin || (loggedInUser && loggedInUser.username === booking.pet.owner.username))) && 
              <button className='btn btn-outline-danger flex-fill'>Cancel</button>}
            </div> }
          </div>
        </div>
        } 
      </div>
    </div>
  )
}

export default BookingTile