import React, { useCallback, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../helpers/ImageHelper'

function ImageCropModal({image, imageFile, show, setShow, onCropped}) {

  const handleClose = () => setShow(false)

  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const cropAndClose = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, imageFile.type)
    onCropped(croppedImage)
    setShow(false)
  }

  return (
    <Modal onEscapeKeyDown={cropAndClose} show={show} onHide={handleClose} centered contentClassName='bg-background text-light montserrat'>
      <Modal.Header>
        <Modal.Title className='display-7'>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{height: '300px'}}>
          <Cropper 
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="mids-mutts" className='btn-block' onClick={cropAndClose}>
          <span>Confirm</span>
        </Button> 
      </Modal.Footer>
    </Modal>
  )
}

export default ImageCropModal