import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams, Link, useLocation } from 'react-router-dom'
import { apiGet } from '../helpers/NetworkHelper'
import moment from 'moment'
import { AuthContext } from '../helpers/AuthContext'
import BookingTile from '../components/bookings/BookingTile'
import { ClipLoader } from "react-spinners"
import { toast } from 'react-toastify'

function Pet() {

  const {loggedInUser} = useContext(AuthContext)

  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const petId = searchParams.get("id")

  const [pet, setPet] = useState(null)
  const [bookingsLoading, setBookingsLoading] = useState(true)
  const [bookings, setBookings] = useState([])
  const [bookingPages, setBookingPages] = useState(0)

  const getPet = useCallback(() => {
    apiGet("pets/", {petId}).then((response) => {
      if (response.status === 200 && response.data.length > 0)
        setPet(response.data[0])
      else
        navigate("/")
    })
  }, [petId, navigate])

  const fetchBookings = useCallback(() => {
    setBookingsLoading(true)
    const params = {
      page: 0,
      pageSize: 8,
      tense: "future",
      petId: petId
    }
    apiGet("bookings", params).then((response) => {
      setBookingsLoading(false)
      console.log(response)
      if (response.status === 200) {
        setBookings(response.data.bookings)
        setBookingPages(response.data.pages)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching bookings!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-bookings-error"
        })
    })
  }, [setBookingPages, setBookings, setBookingsLoading, navigate, petId])

  useEffect(() => {
    getPet()
    fetchBookings()
  }, [getPet, fetchBookings])

  const getAge = (pet) => {
    const now = moment()
    const dob = moment(pet.dob)
    const years = now.diff(dob, 'years')
    const months = now.diff(dob, 'months')
    const weeks = now.diff(dob, 'weeks')
    const happyBirthday = now.date() === dob.date() && now.month() === dob.month()
    let age = ""
    if (years > 0 && months % 12 === 0)
      age = `${years}y`
    else if (years > 0)
      age = `${years}y ${months - 12 * years}m`
    else if (months > 1) 
      age = `${months}m`
    else 
      age = `${weeks} weeks`
    return (
      <span>{age} {happyBirthday && <i className="fa-solid fa-cake-candles text-mids-mutts"></i>}</span>
    )
  }

  const bookingsDisplay = (
    bookings.length > 0 ? 
      <div className='container'>
        <div className='row row-cols-lg-4 row-cols-md-3 row-cols-1'>
          {bookings.map((booking) => (
            <BookingTile booking={booking} showOwner={false} showPet={false} showStatus={true} />
          ))}
        </div> 
      </div>
      :
      <div className='text-center text-subtle display-8 pt-2'>No Results</div>
  )

  return (
    <div>
      { location?.state?.from?.title && <div>
        <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
      </div> }
      { pet && 
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex flex-wrap'>
            <img className='img-responsive rounded-3 mx-md-0 my-auto mx-auto' style={{maxHeight: "200px"}} src={pet.imageKitUrl} alt={pet.name} />
            <div className='p-3'>
              <div className='display-5'>{pet.name}</div>
              <div className='fs-5'>{pet.colour} {pet.breed}</div>
              <div className='fs-5'><span className='text-subtle'>Description:</span> {pet.description}</div>
              <div className='fs-5'><span className='text-subtle'>Age:</span> {getAge(pet)}</div>
              <div className="d-flex flex-wrap py-2 gap-2">
                  { loggedInUser && loggedInUser.username === pet.owner.username && 
                  <Link to={{pathname: "/edit-pet", search: `?id=${pet._id}`}} state={{from: {title: pet?.name ?? "Pet"}}} className='btn btn-outline-info'>Edit</Link> }
              </div>
            </div>
          </div>
          <div className='bg-fore rounded-3 p-3 d-flex flex-column gap-2'>
            <div className='display-7'>Bookings</div>
            <div>
              { bookingsLoading ? 
                <div className='d-flex pt-2'>
                  <ClipLoader className='mx-auto' color="orange" />
                </div>
                : bookingsDisplay
              }
            </div>
            { bookingPages > 1 && 
              <div className='col-lg-12 col-md-12 col-12 p-2 d-flex'>
                <Link className='link-mids-mutts clickable ml-auto' to={{pathname: "/bookings", search: `?petId=${pet._id}`}} state={{from: {title: pet?.name ?? "Pet"}}}><u>View {pet?.name ?? "Pet"}'s Bookings</u></Link>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Pet