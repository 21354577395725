import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { apiPost, getCsrfToken, apiGet } from '../../helpers/NetworkHelper'
import * as Yup from "yup"
import { ClipLoader } from "react-spinners"


function BookingTypeForm() {

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(id ? false : true)

  const [values, setValues] = useState({
    name: "",
    description: "",
    cost: "",
    per: ""
  })

  const getBookingType = useCallback(() => {
    setLoaded(false)
    apiGet("admin/bookings/type", {id}).then((response) => {
      setLoaded(true)
      if (response.status === 200) {
        let type = response.data.type
        setValues({
          ...values,
          id: type._id,
          name: type.name,
          description: type.description,
          cost: type.cost,
          per: type.per
        })
      }
    })
  }, [setValues, navigate, id])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("is required"),
    description: Yup.string().required("is required"),
    cost: Yup.number().notRequired(),
    per: Yup.string().notRequired()
  })

  const onSubmit = (data) => {
    apiPost("admin/bookings/types", values).then((response) => {
      if (response.status === 200)
        navigate('/admin/booking-types')
      else {
        setError(response.data.error)
      }
    })
  }

  useEffect(() => {
    const getBookingTypes = () => {
      apiGet("admin/bookings/types").then((response) => {
        if (response.status === 401) {
          navigate("/")
        } else if (id) {
          getBookingType()
        }
      })
    }
    getBookingTypes()
    getCsrfToken()
  }, [navigate, getBookingType])

  return (
    <div className='row flex flex-column align-items-center'>
      <div className='col-lg-4 col-md-6 col-12 d-flex flex-column'>
      { location?.state?.from?.title && <div className='mb-2'>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
        <div className='display-8 mb-3'>{ id ? "Booking Type" : "New Booking Type"}</div>
        { loaded ? 
        <Formik initialValues={{}} onSubmit={onSubmit}>
          <Form className='loginContainer mb-3'>
            <div className='form-group'>
              <label>Name</label>
              <ErrorMessage className='small text-danger ml-2'  name='name' component="span" />
              <Field value={values.name} onChange={(event) => setValues({...values, name: event.target.value})} className="form-control bg-fore text-light border-0" id="name" name="name" />
            </div>
            <div className='form-group'>
              <label>Description</label>
              <ErrorMessage className='small text-danger ml-2'  name='description' component="span" />
              <Field value={values.description} onChange={(event) => setValues({...values, description: event.target.value})} className='form-control bg-fore text-light border-0' name="description" component="textarea" rows="3" />
            </div>
            <div className='form-group'>
              <label>Cost</label>
              <ErrorMessage className='small text-danger ml-2'  name='cost' component="span" />
              <ErrorMessage className='small text-danger ml-2'  name='per' component="span" />
              <div className="input-group mb-3">
                <span className="input-group-text bg-secondary text-light border-0">£</span>
                <Field value={values.cost} onChange={(event) => setValues({...values, cost: event.target.value})} type="number" className="form-control bg-fore text-light border-0" placeholder="" id="cost" name="cost" />
                <span className="input-group-text bg-secondary text-light border-0">per</span>
                <Field value={values.per} onChange={(event) => setValues({...values, per: event.target.value})} type="text" className="form-control bg-fore text-light border-0" placeholder="" id="per" name="per" />
              </div>
            </div>
            { error && 
              <div className='text-danger'>
                {error}
              </div>
            }
            <button className='btn btn-mids-mutts btn-block mt-4' type='submit'>Save Booking Type</button>
          </Form>
        </Formik>
        :
        <div className='d-flex pt-2'>
          <ClipLoader className='mx-auto' color="orange" />
        </div>
        }
      </div>
    </div>
  )
}

export default BookingTypeForm