import React, { useCallback, useContext, useEffect, useState } from "react";
import BookingSlots from "../components/bookings/BookingSlots";
import { AuthContext } from "../helpers/AuthContext";
import { apiGet } from "../helpers/NetworkHelper";
import Bookings from "../components/home/Bookings";

function Home() {
  const { loggedInUser } = useContext(AuthContext);

  const [bookingTypes, setBookingTypes] = useState([]);

  const getBookingTypes = useCallback(() => {
    apiGet("bookings/types").then((response) => {
      if (response.status === 200) setBookingTypes(response.data.types);
    });
  }, [setBookingTypes]);

  const bookingTypesDisplay = bookingTypes.map((type) => (
    <div key={type._id} className="col-md-6 col-12 p-2">
      <div className="p-3 bg-fore rounded-2 border border-mids-mutts mids-mutts-glow">
        <h2 className="bebas display-6 text-mids-mutts">{type.name}</h2>
        <p>{type.description}</p>
      </div>
    </div>
  ));

  useEffect(() => {
    getBookingTypes();
  }, [getBookingTypes]);

  return (
    <div className="row">
      <div>
        {loggedInUser ? (
          <div className="d-flex flex-column gap-3">
            <Bookings
              username={loggedInUser.username}
              confirmed={true}
              showTense={false}
              showPaginator={false}
              limit={4}
              title={"My Upcoming Bookings"}
              admin={loggedInUser.role.isAdmin}
              collapsable={true}
            />
            <BookingSlots
              availableOnly={true}
              admin={loggedInUser.role.isAdmin}
              collapsable={true}
            />
          </div>
        ) : (
          <div className="text-center">
            <div id="header" className="pb-3">
              <div className="title py-3 text-mids-mutts">
                We are Midlands Mutts
              </div>
              <div className="heading">
                Midlands Mutts offers dog training focused on building a
                confident, calm dog that is easy to live with and can be taken
                anywhere.
              </div>
            </div>
            <div className="row row-cols-md-4 row-cols-1">
              <div className="col p-2">
                <a
                  href="https://www.facebook.com/midlandsmutts/"
                  className="btn btn-secondary"
                >
                  <i class="fa-brands fa-square-facebook"></i>&nbsp;
                  <span className="">Find us on Facebook</span>
                </a>
              </div>
              <div className="col p-2">
                <a
                  href="https://wa.me/+447900166205"
                  className="btn btn-secondary"
                >
                  <i class="fa-brands fa-square-whatsapp"></i>&nbsp;
                  <span className="">Find us on WhatsApp</span>
                </a>
              </div>
              <div className="col p-2">
                <a href="tel:+447900166205" className="btn btn-secondary">
                  <i class="fa-solid fa-phone"></i>&nbsp;
                  <span className="">Give us a call</span>
                </a>
              </div>
              <div className="col p-2">
                <a
                  href="mailto:midlandsmutts@gmail.com"
                  className="btn btn-secondary"
                >
                  <i class="fa-solid fa-envelope"></i>&nbsp;
                  <span className="">Send us an email</span>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
