import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { apiDelete, apiGet, apiPost } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import BookingTile from '../bookings/BookingTile'
import { ClipLoader } from "react-spinners"
import { AuthContext } from '../../helpers/AuthContext'
import { Collapse } from 'react-bootstrap'

function Bookings({collapsable}) {

  const { loggedInUser } = useContext(AuthContext)
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [bookings, setBookings] = useState([])
  const [show, setShow] = useState(true || !collapsable)
  const [pages, setPages] = useState(0)

  const requestRequirements = [navigate, loggedInUser]

  const fetch = useCallback(() => {
    setLoading(true)
    const params = {
      page: 0,
      pageSize: 4,
      tense: "future"
    }
    if (loggedInUser)
      params.username = loggedInUser.username
    apiGet("bookings", params).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setBookings(response.data.bookings)
        setPages(response.data.pages)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching bookings!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-bookings-error"
        })
    })
  }, requestRequirements)

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <React.Fragment>
      {bookings.length > 0 &&
        <div className='container py-2 bg-fore rounded-3'>
          <div className='d-flex flex-wrap align-items-center p-2'>
            <div className='display-8 me-2'>My Upcoming Bookings</div>
            { collapsable && 
              <button className='btn btn-dark border-0 text-mids-mutts' onClick={() => setShow(!show)}>
                <i className={show ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
              </button>
            }
          </div>
          <Collapse in={show}>
            <div>
              <div className='container'>
                <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 bg-fore rounded-3 p-1'>
                  { (bookings.length === 0 && !loading) &&
                    <div className='col-lg-12 col-md-12 col-12 text-center text-subtle display-8 pt-2'>No Results</div>
                  }
                  { loading ? 
                    <div className='col-lg-12 col-md-12 col-12 d-flex pt-2'>
                      <ClipLoader className='mx-auto' color="orange" />
                    </div>
                    : 
                    bookings.map((booking) => (
                      <BookingTile key={booking._id} booking={booking} admin={false} showPet={true} />
                    ))}
                  { pages > 1 && 
                    <div className='col-lg-12 col-md-12 col-12 p-2 mt-1 d-flex'>
                      <div className='link-mids-mutts clickable ml-auto' onClick={() => navigate('/bookings')}><u>View All Bookings</u></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Collapse>
        </div> 
      }
    </React.Fragment>
  )
}

export default Bookings