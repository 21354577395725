import React, { useState } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { formatDate, formatTime } from '../../helpers/DateTimeUtils'
import { Tooltip } from 'react-tooltip'
import PetBookingRequestModal from './PetBookingRequestModal'
import { getHourDiff, getMinuteDiff } from '../../helpers/DateTimeUtils'

function BookingSlotTile({slot, deleteBookingSlot, admin, refresh}) {

  const navigate = useNavigate()

  const [show, setShow] = useState(false)
  const [selectedBookingSlot, setSelectedBookingSlot] = useState(null)

  const manageBookingSlot = (id) => {
    navigate({pathname: '/booking-slot', search: `?${createSearchParams({id: id})}`})
  }
  const choosePetDialog = (slot) => {
    setSelectedBookingSlot(slot)
    setShow(true)
  }

  const getHoursLabel = (hours) => hours > 1 ? " hours" : " hour"
  const getMinutesLabel = (minutes) => minutes > 1 ? " mins" : " min"
  
  const getDuration = () => {
    let hours = getHourDiff(slot.start, slot.end)
    let minutes = getMinuteDiff(slot.start, slot.end)
    return `${hours > 0 ? hours + getHoursLabel(hours) : ""} ${minutes > 0 ? minutes +  getMinutesLabel(minutes) : ""}`
  }

  return (
    <div className='col p-2'>
      <div className='h-100 border border-mids-mutts rounded-3 bg-fore p-3 d-flex flex-column'>
        <div className='display-8 mb-2'>{slot.bookingType.name}</div>
        <div className='d-flex'>Date: <span className='ms-auto'>{formatDate(slot.start)}</span></div>
        <div className='d-flex'>Time: <span className='ms-auto'>{formatTime(slot.start)}</span></div>
        <div className='d-flex'>Duration: <span className='ms-auto'>{getDuration()}</span></div>
        <div className='d-flex'>Spaces Left: <span className='ms-auto'>{slot.spacesLeft}</span></div>
        { slot.info && 
          <div className='bg-background rounded-3 p-2 my-2'>{slot.info}</div>
        }
        { admin ?
        <div className='mt-auto'>
          <div className='mt-2 d-flex'>
            <button className='btn btn-outline-mids-mutts flex-fill me-2' onClick={() => manageBookingSlot(slot._id)}>Manage</button>
            <button className='btn btn-outline-danger' onClick={() => {deleteBookingSlot(slot)}} data-tooltip-place="bottom" data-tooltip-id="delete-tooltip" data-tooltip-content="Delete"><i className="fa-solid fa-trash"></i></button>
            <Tooltip id="delete-tooltip" />
          </div>
        </div> 
        :
        <div className='mt-auto'>
          <div className='mt-2 d-flex'>
            { slot.spacesLeft > 0 && <button className='btn btn-outline-mids-mutts flex-fill' onClick={() => choosePetDialog(slot)}>Request Booking</button>}
          </div> 
        </div> }
      </div>

      <PetBookingRequestModal show={show} setShow={setShow} selectedBookingSlot={selectedBookingSlot} refresh={refresh} />
    </div>
  )
}

export default BookingSlotTile