import React, { useCallback, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { apiPost, apiPostFormData } from '../../helpers/NetworkHelper'
import { AuthContext } from '../../helpers/AuthContext'

function ShopItemForm() {

  const {loggedInUser} = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const [error, setError] = useState(null)
  const [itemSizes, setItemSizes] = useState([
    {
      description: "",
      price: "",
    }
  ])
  const [values, setValues] = useState({})

  const [imageFile, setImageFile] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)

  const setImage = (input) => {
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        setSelectedImage(e.target.result)
      }
      reader.readAsDataURL(input.files[0])
      setImageFile(input.files[0])
    }
  }

  const addItemSize = () => {
    setItemSizes([...itemSizes, {
      description: "",
      price: "",
    }])
  }

  const removeItemSize = (index) => {
    var itemSizesCopy = [...itemSizes]
    itemSizesCopy.splice(index, 1)
    setItemSizes([...itemSizesCopy])
  }

  const updateItemSizeDescription = (index, description) => {
    var itemSizesCopy = [...itemSizes]
    var itemSize = {...itemSizesCopy[index]}
    itemSize.description = description
    itemSizesCopy[index] = itemSize
    setItemSizes([...itemSizesCopy])
  }

  const updateItemSizePrice = (index, price) => {
    var itemSizesCopy = [...itemSizes]
    var itemSize = {...itemSizesCopy[index]}
    itemSize.price = price
    itemSizesCopy[index] = itemSize
    setItemSizes([...itemSizesCopy])
  }

  const postItem = async (data) => {
    let formData = new FormData()
    if (imageFile) {
      let file = await fetch(selectedImage).then(r => r.blob()).then(blobFile => new File([blobFile], imageFile.name, { type: imageFile.type }))
      formData.append('image', file)
    }
    formData.append('description', values.description)
    formData.append('longDescription', values.longDescription)
    formData.append('itemSizes', JSON.stringify(itemSizes))
    // if (id)
    //   formData.append('id', id)
    console.log(JSON.stringify(itemSizes))
    console.log(JSON.parse(JSON.stringify(itemSizes)))
    apiPostFormData("shop/items", formData).then((response) => {
      if (response.status === 200) {
        setError(null)
        navigate({pathname: "/shop/items"})
      } else {
        setError(response.data.error)
      }
    })
  }

  const sizesForm = (
    <div className='d-flex flex-column gap-3'>
      { itemSizes.map((itemSize, index) => (
        <div key={itemSize._id ?? "itemSize" + index} className='bg-fore rounded-2 p-3 mt-2'>
          <div className='form-group'>
            <div className='d-flex flex-row align-items-end'><label>Description</label>{ itemSizes.length > 1 && <button onClick={() => {removeItemSize(index)}} className='ms-auto mb-3 btn btn-sm btn-outline-danger'><i class="fa-solid fa-trash"></i></button>}</div>
            <ErrorMessage className='small text-danger ml-2' name='name' component="span" />
            <Field value={itemSizes[index].description} onChange={(event) => updateItemSizeDescription(index, event.target.value)} className="form-control form-control-sm bg-background text-light border-0" id="description" name="description" />
          </div>
          <div className='form-group mb-0 form-group-sm'>
            <label>Price</label>
            <ErrorMessage className='small text-danger ml-2'  name='price' component="span" />
            <div className="input-group">
              <span className="input-group-text bg-secondary text-light border-0">£</span>
              <Field type="number" value={itemSizes[index].price} onChange={(event) => updateItemSizePrice(index, event.target.value)} className="form-control form-control-sm bg-background text-light border-0" name="cost" />
            </div>
          </div>
        </div>
      )) }
      <button onClick={addItemSize} className='btn btn-block btn-success'><i class="fa-solid fa-plus"></i>&nbsp;Add Size</button>
    </div>
  )

  if (loggedInUser && !loggedInUser.role.isAdmin) {
    navigate("/")
  }

  return (
    <div className='d-flex flex-column gap-3'>
      { location?.state?.from?.title && <div>
        <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
      </div> }
      <div className='display-8'>New Item</div>
      <Formik initialValues={{}} onSubmit={postItem}>
        <Form className='loginContainer mb-3'>
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <div className='form-group'>
                <label>Description</label>
                <ErrorMessage className='small text-danger ml-2'  name='name' component="span" />
                <Field value={values.description} onChange={(event) => setValues({...values, description: event.target.value})} className="form-control bg-fore text-light border-0" id="description" name="description" />
              </div>
              <div className='form-group'>
                <label>Long Description</label>
                <ErrorMessage className='small text-danger ml-2'  name='longDescription' component="span" />
                <Field value={values.longDescription} onChange={(event) => setValues({...values, longDescription: event.target.value})} className='form-control bg-fore text-light border-0' name="longDescription" component="textarea" rows="3" />
              </div>
              <div className='form-group'>
                <label>Item Image</label>
                <ErrorMessage className='small text-danger ml-2'  name='image' component="span" />
                { selectedImage && 
                  <div className='d-flex mb-3'>
                    <img src={selectedImage} alt='Item' className='img-fluid w-50 mx-auto' />
                  </div>
                }
                <input type="file" name="image" className="form-control bg-fore text-light border-0" id="image" accept="image/*" onChange={(event) => {
                  setImage(event.currentTarget);
                }} />
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div>Sizes</div>
              {sizesForm}
            </div>
          </div>
          { error && 
            <div className='text-danger'>
              {error}
            </div>
          }
          <button className='btn btn-mids-mutts btn-block mt-4' type='submit'>Save Item</button>
        </Form>
      </Formik>
    </div>
  )
}

export default ShopItemForm