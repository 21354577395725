import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { perPageOptions, paginator } from '../../helpers/Pagination'
import { apiGet } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import { Modal, Button } from 'react-bootstrap'
import { ClipLoader } from "react-spinners"

function Users() {

  const navigate = useNavigate()
  const location = useLocation()

  const savedResultsPerPage = sessionStorage.getItem("resultsPerPage")
  const initialResultsPerPage = !savedResultsPerPage || isNaN(savedResultsPerPage) ? 16 : parseInt(savedResultsPerPage)
  
  const [users, setUsers] = useState([])
  const [perPage, setPerPage] = useState(initialResultsPerPage)
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)

  const [showBanish, setShowBanish] = useState(false)
  const [actionableUser, setActionableUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const handleClose = () => setShowBanish(false)

  const banish = () => {
    setActionableUser(null)
    setShowBanish(false)
  }

  const confirmBanish = (user) => {
    setActionableUser(user)
    setShowBanish(true)
  }

  const banishUserModal = (
    <Modal size='sm' show={showBanish} onHide={handleClose} contentClassName='bg-background text-light montserrat'>
      <Modal.Body>
        { actionableUser && <div>Are you sure you want to banish {actionableUser.firstname} {actionableUser.lastname}?</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" className='btn-block' onClick={banish}>
          Banish
        </Button>
        <Button variant="secondary" className='btn-block' onClick={() => {
          setActionableUser(null)
          handleClose()
        }}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )

  const state = {
    from: {
      title: "Users"
    }
  }

  const userCol = (user) => {
    let bookingCount = 0
    for (let i = 0; i < Object.keys(user.pets).length; i++)
      bookingCount += Object.keys(user.pets[i].bookings).length
    return (
      <div key={user.username} className='col p-2'>
        <div className='border border-mids-mutts rounded-3 p-3'>
          <div className='display-8 mb-2'>{user.firstname} {user.lastname}</div>
          <div>Pets: {Object.keys(user.pets).length}</div>
          <div>Upcoming Bookings: {bookingCount}</div>
          <div className='mt-2 d-flex flex-column gap-2'>
            <Link to={{pathname: "/bookings", search: `?username=${user.username}`}} state={state} className='btn btn-outline-mids-mutts flex-fill'>View Bookings</Link>
            <Link to={{pathname: "/profile", search: `?username=${user.username}`}} state={state} className='btn btn-outline-mids-mutts flex-fill'>View Profile & Pets</Link>
            <button className='btn btn-outline-danger flex-fill' onClick={() => confirmBanish(user)}>Banish</button>
          </div>
        </div>
      </div>
    )
  }

  const usersCols = (
    <React.Fragment>
      { users.map((user) => (
        userCol(user)
      ))}
    </React.Fragment>
  )

  const getUsers = useCallback(() => {
    setLoading(true)
    apiGet("admin/users", {
      page: page,
      pageSize: perPage
    }).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setUsers(response.data.users)
        setPageCount(response.data.pages)
      } else if (response.status === 401) {
        navigate("/")
      } else {
        toast.error(`Error fetching users!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-users-error"
        })
      }
    })
  }, [page, perPage, navigate])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  return (
    <div>
      { location?.state?.from?.title && <div className='mb-2'>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
      <div className='d-sm-flex flex-wrap align-items-center'>
        <p className='display-6'>Users</p>
      </div>
      <div className='mb-4 container border-top border-mids-mutts pt-4 mt-4'>
        { (users.length === 0 && !loading) &&
          <div className='text-center text-subtle display-8 pt-2'>No Results</div>
        }
        { loading ? 
          <div className='d-flex pt-2'>
            <ClipLoader className='mx-auto' color="orange" />
          </div>
          : 
          <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4'>
            {usersCols}
          </div> 
        }
      </div>
      <div className='d-flex justify-content-center border-top border-mids-mutts pt-4 mt-4'>
        { users.length > 0 &&
          paginator(page, pageCount, setPage)
        }
      </div>
      <div className='d-flex flex-wrap justify-content-start'>
        <div className='py-3'>
          <div className='text-subtle'>Results per page: {perPageOptions(3, 8, 16, perPage, setPerPage)}</div>
        </div>
      </div>
      {banishUserModal}
    </div>
  )
}

export default Users