import React, { useEffect, useState, useContext, useCallback } from 'react'
import {Formik, Form, Field, ErrorMessage} from "formik"
import { apiGet, apiPostFormData, getCsrfToken } from '../helpers/NetworkHelper'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { AuthContext } from '../helpers/AuthContext'
import ImageCropModal from '../components/ImageCropModal'
import moment from 'moment'
import { ClipLoader } from "react-spinners"

function PetForm() {

  const {loggedInUser} = useContext(AuthContext)

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const [imageFile, setImageFile] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [error, setError] = useState(null)

  const [showCrop, setShowCrop] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)

  const [pet, setPet] = useState({})
  const [values, setValues] = useState(null)
  const [loaded, setLoaded] = useState(id ? false : true)

  const getPet = useCallback(() => {
    setLoaded(false)
    apiGet("pets/", {id}).then((response) => {
      if (response.status === 200 && response.data.length > 0) {
        setPet(response.data[0])
        setValues({
          ...response.data[0],
          dob: moment(response.data[0].dob).format("yyyy-MM-DD")
        })
        setCroppedImage(response.data[0].imageKitUrl)
      } else
        navigate("/")
    })
  }, [])

  const onImageCropped = (image) => {
    setCroppedImage(image)
  }

  const upload = async (data) => {
    let formData = new FormData()
    if (imageFile) {
      let file = await fetch(croppedImage).then(r => r.blob()).then(blobFile => new File([blobFile], imageFile.name, { type: imageFile.type }))
      formData.append('image', file)
    }
    formData.append('name', data.name)
    formData.append('breed', data.breed)
    formData.append('colour', data.colour)
    formData.append('description', data.description)
    formData.append('dob', data.dob)
    if (id)
      formData.append('id', id)
    apiPostFormData("pets", formData).then((response) => {
      if (response.status === 200) {
        setError(null)
        navigate({pathname: "/profile", search: `?username=${loggedInUser.username}`})
      } else {
        setError(response.data.error)
      }
    })
  }

  const setImage = (input) => {
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        setSelectedImage(e.target.result)
      }
      reader.readAsDataURL(input.files[0])
      setImageFile(input.files[0])
      setShowCrop(true)
    }
  }

  useEffect(() => {
    getCsrfToken()
    if (id)
      getPet()
  }, [getPet])

  return (
    <div className='row flex flex-column align-items-center gap-3'>
      { location?.state?.from?.title && <div className='col-lg-8 col-12'>
        <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
      </div> }
      { id && <div className='col-lg-8 col-12 display-8'>Editing {pet.name}</div> }
      <div className='col-lg-8 col-12'>
        { (!id || values) ? <Formik initialValues={values ?? {}} onSubmit={upload}>
          <Form className='loginContainer' encType="multipart/form-data" >
            <div className='row'>
              <div className='col-lg-6 col-12'>
                <div className='form-group'>
                  <label>Name</label>
                  <ErrorMessage className='small text-danger ml-2'  name='name' component="span" />
                  <Field className="form-control bg-fore text-light border-0" id="inputCreatePost" name="name" />
                </div>
                <div className='form-group'>
                  <label>Breed</label>
                  <ErrorMessage className='small text-danger ml-2'  name='breed' component="span" />
                  <Field className="form-control bg-fore text-light border-0" id="inputCreatePost" name="breed" />
                </div>
                <div className='form-group'>
                  <label>Colour</label>
                  <ErrorMessage className='small text-danger ml-2'  name='colour' component="span" />
                  <Field className="form-control bg-fore text-light border-0" id="inputCreatePost" name="colour" />
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='form-group'>
                  <label>Date of Birth</label>
                  <ErrorMessage className='small text-danger ml-2'  name='dob' component="span" />
                  <Field className="form-control bg-fore text-light border-0" id="inputCreatePost" type="date" name="dob" />
                </div>
                <div className='form-group'>
                  <label>Description</label>
                  <ErrorMessage className='small text-danger ml-2'  name='description' component="span" />
                  <Field className="form-control bg-fore text-light border-0" id="inputCreatePost" name="description" />
                </div>
              </div>
              <div className='form-group col-lg-6 col-12'>
                <label>Pet Picture</label>
                <ErrorMessage className='small text-danger ml-2'  name='username' component="span" />
                { croppedImage && 
                  <div className='d-flex mb-3'>
                    <img onClick={() => setShowCrop(true)} src={croppedImage} alt='Pet' className='img-fluid w-50 mx-auto' />
                  </div>
                }
                <input type="file" name="image" className="form-control bg-fore text-light border-0" id="image" accept="image/*" onChange={(event) => {
                  setImage(event.currentTarget);
                }} />
              </div>
            </div>
            { error && <p className='small text-danger'>{error}</p> }
            <button className='btn btn-mids-mutts btn-block mt-4' type='submit'>Save Pet</button>
          </Form>
        </Formik> 
        : !loaded ? 
        <div className='d-flex pt-2'>
          <ClipLoader className='mx-auto' color="orange" />
        </div>
        : <div></div> }
      </div>
      { selectedImage && 
        <ImageCropModal image={selectedImage} imageFile={imageFile} show={showCrop} setShow={setShowCrop} onCropped={onImageCropped} />
      }
    </div>
  )
}

export default PetForm