import React, { useCallback, useEffect, useState, useContext } from 'react'
import { apiGet, apiDelete } from '../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import BookingSlotTile from '../components/bookings/BookingSlotTile'
import { paginator, perPageOptions } from '../helpers/Pagination'
import { ClipLoader } from "react-spinners"
import { AuthContext } from '../helpers/AuthContext'

function BookingSlots() {

  const {loggedInUser} = useContext(AuthContext)

  const navigate = useNavigate()
  const location = useLocation()

  const savedResultsPerPage = sessionStorage.getItem("resultsPerPage")
  const initialResultsPerPage = !savedResultsPerPage || isNaN(savedResultsPerPage) ? 16 : parseInt(savedResultsPerPage)

  const [loading, setLoading] = useState(true)
  const [searchParams] = useSearchParams()
  const [tense, setTense] = useState(sessionStorage.getItem("tense") ?? "future")
  const [perPage, setPerPage] = useState(initialResultsPerPage)
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [bookingSlots, setBookingSlots] = useState([])
  const [bookingTypes, setBookingTypes] = useState([])
  const [typeId, setTypeId] = useState(searchParams.get('typeId') ?? "null")

  const isAdmin = () => {
    return (loggedInUser && loggedInUser.role.isAdmin)
  }

  const getBookingSlots = useCallback(() => {
    setLoading(true)
    const params = {
      page: page,
      pageSize: perPage,
      tense: tense
    }
    if (typeId !== "null")
      params.typeId = typeId
    apiGet("bookings/slots", params).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setBookingSlots(response.data.slots)
        setPageCount(response.data.pages)
        setBookingTypes(response.data.types)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching booking slots!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-slots-error"
        })
    })
  }, [page, perPage, tense, typeId, navigate])

  useEffect(() => {
    getBookingSlots()
  }, [getBookingSlots])

  const deleteBookingSlot = (bookingSlot) => {
    apiDelete('admin/bookings/slots', bookingSlot._id).then((response) => {
      if (response.status === 200) {
        toast('Deleted Slot', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Deleted_Slot_${bookingSlot._id}_Toast`
        })
        getBookingSlots()
      } else {
        toast.error(response.data.error, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000
        })
      }
    })
  }

  const bookingSlotsCols = (
    <React.Fragment>
      { bookingSlots.map((slot) => (
        <BookingSlotTile key={slot._id} slot={slot} deleteBookingSlot={deleteBookingSlot} admin={isAdmin()} refresh={getBookingSlots}  />
      ))}
    </React.Fragment>
  )

  const tenseOptions = () => {
    const tenses = ["past", "future", "both"]
    const tenseOptions = []
    for (let i = 0; i < tenses.length; i++) {
      const iTense = tenses[i]
      const css = iTense === tense ? "btn-secondary" : "btn-outline-secondary text-subtle"
      tenseOptions.push((
        <button key={"tenseOptions_button_" + i} onClick={() => {
          if (iTense !== tense) {
            sessionStorage.setItem("tense", iTense)
            setTense(iTense)
          }
        }} className={"btn " + css}>{iTense}</button>
      ))
      if (i !== tenses.length - 1) {
        tenseOptions.push((
          <span key={"tenseOptions_span_" + i} className='text-subtle px-1'>|</span>
        ))
      }
    } 
    return (
      <React.Fragment>
        {tenseOptions}
      </React.Fragment>
    )
  }

  const typeOptions = (
    <div className='d-flex'>
      <span className="text-subtle py-2 pe-2" style={{fontSize: 0.9 + "rem"}} id="basic-addon1">Type:</span>
      <select id='type-select' className='form-select form-select-sm bg-fore border-0 text-light' value={typeId} onChange={(event) => {
        setTypeId(event.currentTarget.value)
      }}>
        <option key={"typeOptions_option_null"} value={"null"}>Any</option>
        {bookingTypes.map((type) => (
          <option key={"typeOptions_option_" + type._id} value={type._id}>{type.name}</option>
        ))}
      </select>
    </div>
  )

  return (
    <div>
      { location?.state?.from?.title && <div className='mb-2'>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
      <div className='d-sm-flex flex-wrap align-items-center'>
        <p className='display-6 py-2 m-0'>Booking Slots</p>
        <div className='p-2 ms-auto'>
          { isAdmin() &&
            <button onClick={() => navigate("/admin/booking-slots/new")} className='btn btn-sm btn-outline-mids-mutts'>Add New Slot</button> 
          }
        </div>
      </div>
      <div className='d-sm-flex flex-wrap justify-content-start'>
        <div className='p-2'>
          {typeOptions}
        </div>
        <div className='p-2'>
          <div className='text-subtle'>When: {tenseOptions()}</div>
        </div>
      </div>
      <div className='mb-4 container border-top border-mids-mutts pt-4 mt-4'>
        { (bookingSlots.length === 0 && !loading) &&
          <div className='text-center text-subtle display-8 pt-2'>No Results</div>
        }
        { loading ? 
        <div className='d-flex pt-2'>
          <ClipLoader className='mx-auto' color="orange" />
        </div>
         : 
        <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4'>
          {bookingSlotsCols}
        </div> }
      </div>
      <div className='d-flex justify-content-center border-top border-mids-mutts pt-4 mt-4'>
        { bookingSlots.length > 0 &&
          paginator(page, pageCount, setPage)
        }
      </div>
      <div className='d-flex flex-wrap justify-content-start'>
        <div className='py-3'>
          <div className='text-subtle'>Results per page: {perPageOptions(3, 8, 16, perPage, setPerPage)}</div>
        </div>
      </div>
    </div>
  )
}

export default BookingSlots