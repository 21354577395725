import React, { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useLocation, useNavigate, useSearchParams, Link } from 'react-router-dom'
import { apiGet } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import BookingTile from '../../components/bookings/BookingTile'

function BookingSlot() {

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const [bookingSlot, setBookingSlot] = useState(null)

  const fetch = useCallback(() => {
    apiGet("admin/bookings/slot", {id: id}).then((response) => {
      if (response.status === 200) {
        setBookingSlot(response.data.slot)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching booking slots!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-slots-error"
        })
    })
  }, [id, navigate, setBookingSlot])

  const bookingsDisplay = (
    <div className='bg-fore rounded-3 p-2 row row-cols-1 row-cols-md-3 row-cols-lg-4'>
      { bookingSlot && 
        bookingSlot.bookings.map((booking) => (
          <BookingTile booking={booking} bookingSlot={bookingSlot} admin={true} showOwner={true} showPet={true} />
        ))
      }
    </div>
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <div>
      <div className='d-flex flex-column'>
        { location?.state?.from?.title && <div className='mb-2'>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
        <div className='display-7 mb-3 d-flex'>Booking Slot {bookingSlot && <span>- {bookingSlot.bookingType.name}</span>}<Link className='ms-auto btn btn-outline-info' state={{from: {title: 'Booking Slot'}}} to={{pathname: "/admin/booking-slots/new", search: `?${createSearchParams({id: bookingSlot._id})}`}}><i className="fa-solid fa-edit"></i></Link></div>
        <div className='display-8 mb-3'>Bookings</div>
        { bookingsDisplay }
      </div>
    </div>
  )
}

export default BookingSlot