import React, { useCallback, useEffect, useState, useContext } from 'react'
import { apiGet, apiPost } from '../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import BookingTile from '../components/bookings/BookingTile'
import { paginator, perPageOptions } from '../helpers/Pagination'
import { ClipLoader } from "react-spinners"
import { AuthContext } from '../helpers/AuthContext'

function Bookings() {

  const {loggedInUser} = useContext(AuthContext)

  const navigate = useNavigate()
  const location = useLocation()

  const savedResultsPerPage = sessionStorage.getItem("resultsPerPage")
  const initialResultsPerPage = !savedResultsPerPage || isNaN(savedResultsPerPage) ? 16 : parseInt(savedResultsPerPage)

  const [loadingBookings, setLoadingBookings] = useState(true)
  const [loadingRequests, setLoadingRequests] = useState(true)
  const [searchParams] = useSearchParams()

  const username = searchParams.get("username")
  const petId = searchParams.get("petId")
  const requestsOnly = searchParams.get("requestsOnly") === "true"

  const [tense, setTense] = useState(sessionStorage.getItem("tense") ?? "future")
  const [perPage, setPerPage] = useState(initialResultsPerPage)
  const [requestsPage, setRequestsPage] = useState(0)
  const [bookingsPage, setBookingsPage] = useState(0)
  const [requestsPageCount, setRequestsPageCount] = useState(0)
  const [bookingsPageCount, setBookingsPageCount] = useState(0)
  const [requests, setRequests] = useState([])
  const [bookings, setBookings] = useState([])
  const [bookingTypes, setBookingTypes] = useState([])
  const [typeId, setTypeId] = useState(searchParams.get('typeId') ?? "null")
  const [user, setUser] = useState(null)
  const [pet, setPet] = useState(null)

  const getBookings = useCallback(() => {
    setLoadingBookings(true)
    const params = {
      page: bookingsPage,
      pageSize: perPage,
      tense: tense
    }
    if (username)
      params.username = username
    if (typeId !== "null")
      params.typeId = typeId
    if (petId)
      params.petId = petId
    apiGet("bookings", params).then((response) => {
      setLoadingBookings(false)
      if (response.status === 200) {
        setBookings(response.data.bookings)
        setBookingsPageCount(response.data.bookingsPageCount)
        setBookingTypes(response.data.types)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching bookings!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-bookings-error"
        })
    })
  }, [bookingsPage, perPage, tense, typeId, navigate, username])

  const requestRequirements =  [username, navigate, petId, requestsPage]
  if (requestsOnly)
    requestRequirements.push(tense)

  const getRequests = useCallback(() => {
    setLoadingRequests(true)
    const params = {
      page: requestsPage,
      pageSize: requestsOnly ? perPage : 4
    }
    if (username)
      params.username = username
    if (petId)
      params.petId = petId
    if (requestsOnly) {
      if (typeId !== "null")
        params.typeId = typeId
      params.tense = tense
    }
    apiGet("bookings/requests", params).then((response) => {
      setLoadingRequests(false)
      console.log(response)
      if (response.status === 200) {
        setRequests(response.data.requests)
        setRequestsPageCount(response.data.requestsPageCount)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching requests!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-requests-error"
        })
    })
  }, requestRequirements)

  const isAdmin = () => {
    return loggedInUser && loggedInUser.role.isAdmin
  }

  useEffect(() => {
    if (!requestsOnly) {
      getBookings()
    }
  }, [getBookings])

  
  useEffect(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    const getUser = async (username) => {
      apiGet("users", {username: username}).then((response) => {
        if (response.status === 200) {
          setUser(response.data)
        } else {
          navigate("/")
        }
      })
    }
    const getPet = async (petId) => {
      apiGet("pets/", {petId}).then((response) => {
        if (response.status === 200 && response.data.length > 0)
          setPet(response.data[0])
        else
          navigate("/")
      })
    }
    if (username)
      getUser(username)
    if (petId) 
      getPet(petId)
  }, [navigate, petId, username])

  const tenseOptions = () => {
    const tenses = ["past", "future", "both"]
    const tenseOptions = []
    for (let i = 0; i < tenses.length; i++) {
      const iTense = tenses[i]
      const css = iTense === tense ? "btn-secondary" : "btn-outline-secondary text-subtle"
      tenseOptions.push((
        <button key={"tenseOptions_button_" + i} onClick={() => {
          if (iTense !== tense) {
            sessionStorage.setItem("tense", iTense)
            setTense(iTense)
          }
        }} className={"btn " + css}>{iTense}</button>
      ))
      if (i !== tenses.length - 1) {
        tenseOptions.push((
          <span key={"tenseOptions_span_" + i} className='text-subtle px-1'>|</span>
        ))
      }
    } 
    return (
      <React.Fragment>
        {tenseOptions}
      </React.Fragment>
    )
  }

  const typeOptions = (
    <div className='d-flex'>
      <span className="text-subtle py-2 pe-2" style={{fontSize: 0.9 + "rem"}} id="basic-addon1">Type:</span>
      <select id='type-select' className='form-select form-select-sm bg-fore border-0 text-light' value={typeId} onChange={(event) => {
        setTypeId(event.currentTarget.value)
      }}>
        <option key={"typeOptions_option_null"} value={"null"}>Any</option>
        {bookingTypes.map((type) => (
          <option key={"typeOptions_option_" + type._id} value={type._id}>{type.name}</option>
        ))}
      </select>
    </div>
  )

  const acceptRequest = (request) => {
    apiPost("admin/bookings/requests/accept", {
      bookingId: request._id,
      specifyTime: false
    }).then((response) => {
      if (response.status === 401) {
        navigate("/")
      } else if (response.status === 200) {
        toast('Booking Accepted', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Booking_Accepted_${request._id}_Toast`
        })
        getRequests()
      }
    })
  }

  const rejectRequest = (request) => {
    apiPost("admin/bookings/requests/reject", {
      bookingId: request._id
    }).then((response) => {
      if (response.status === 401) {
        navigate("/")
      } else if (response.status === 200) {
        toast('Booking Rejected', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Booking_Rejected_${request._id}_Toast`
        })
        getRequests()
      }
    })
  }

  const pendingRequestsTitle = () => {
    const petName = pet ? pet.name : null
    const ownerName = user ? user.firstname : null
    return (petName ? petName + "'s " : ownerName ? ownerName + "'s " : "") + "Requests"
  }

  const bookingsTitle = () => {
    const petName = pet ? pet.name : null
    const ownerName = user ? user.firstname : null
    return (petName ? petName + "'s " : ownerName ? ownerName + "'s " : "") + "Bookings"
  }

  return (
    <div>
      { location?.state?.from?.title && <div className='mb-2'>
          <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
        </div> }
      { (requests.length > 0 || requestsOnly) && <div>
        <p className='display-6 py-2 m-0'>{pendingRequestsTitle()}</p>
        { requestsOnly && 
          <div className='d-sm-flex flex-wrap justify-content-start'>
            <div className='p-2'>
              {typeOptions}
            </div>
            <div className='p-2'>
              <div className='text-subtle'>When: {tenseOptions()}</div>
            </div>
          </div> }
        <div className='mb-4 container border-top border-mids-mutts pt-4 mt-4'>
          { (requests.length === 0 && !loadingRequests) &&
            <div className='text-center text-subtle display-8 pt-2'>No Results</div>
          }
          { loadingRequests ? 
          <div className='d-flex pt-2'>
            <ClipLoader className='mx-auto' color="orange" />
          </div>
          : 
          <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4'>
            { requests.map((request) => (
              <BookingTile key={request._id} booking={request} acceptRequest={acceptRequest} rejectRequest={rejectRequest} showPet={!pet || pet._id !== request.pet._id} showOwner={!user || user.username !== request.pet.owner.username} />
            ))}
          </div> }
        </div>
        <div className='d-flex justify-content-center border-top border-mids-mutts pt-4 mt-4'>
          { requests.length > 0 &&
            paginator(requestsPage, requestsPageCount, setRequestsPage)
          }
        </div>
      </div> }
      { !requestsOnly && 
      <div>
        <p className='display-6 py-2 m-0'>{bookingsTitle()}</p>
        <div className='d-sm-flex flex-wrap justify-content-start'>
          <div className='p-2'>
            {typeOptions}
          </div>
          <div className='p-2'>
            <div className='text-subtle'>When: {tenseOptions()}</div>
          </div>
        </div>
        <div className='mb-4 container border-top border-mids-mutts pt-4 mt-4'>
          { (bookings.length === 0 && !loadingBookings) &&
            <div className='text-center text-subtle display-8 pt-2'>No Results</div>
          }
          { loadingBookings ? 
          <div className='d-flex pt-2'>
            <ClipLoader className='mx-auto' color="orange" />
          </div>
          : 
          <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4'>
            { bookings.map((booking) => (
              <BookingTile key={booking._id} booking={booking} admin={isAdmin} showPet={!pet || pet._id !== booking.pet._id} showOwner={!user || user.username !== booking.pet.owner.username} />
            ))}
          </div> }
        </div>
        <div className='d-flex justify-content-center border-top border-mids-mutts pt-4 mt-4'>
          { bookings.length > 0 &&
            paginator(bookingsPage, bookingsPageCount, setBookingsPage)
          }
        </div>
      </div> }
      <div className='d-flex flex-wrap justify-content-start'>
        <div className='py-3'>
          <div className='text-subtle'>Results per page: {perPageOptions(3, 8, 16, perPage, setPerPage)}</div>
        </div>
      </div>
    </div>
  )
}

export default Bookings