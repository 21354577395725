import React, { useCallback, useEffect, useState } from 'react'
import { apiGet, apiDelete } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'
import { paginator, perPageOptions } from '../../helpers/Pagination'
import { ClipLoader } from "react-spinners"

function BookingTypes() {

  const navigate = useNavigate()

  const savedResultsPerPage = sessionStorage.getItem("resultsPerPage")
  const initialResultsPerPage = !savedResultsPerPage || isNaN(savedResultsPerPage) ? 16 : parseInt(savedResultsPerPage)

  const [loading, setLoading] = useState(true)

  const [bookingTypes, setBookingTypes] = useState([])
  const [perPage, setPerPage] = useState(initialResultsPerPage)
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState(0)

  const getBookingTypes = useCallback(() => {
    setLoading(true)
    apiGet("bookings/types", {
      page: page,
      pageSize: perPage
    }).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setBookingTypes(response.data.types)
        setPageCount(response.data.pages)
      } else if (response.status === 401) {
        navigate("/")
      } else
        toast.error(`Error fetching booking slots!`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
          toastId: "fetch-types-error"
        })
    })
  }, [page, perPage, navigate])

  useEffect(() => {
    getBookingTypes()
  }, [getBookingTypes])

  const manageBookingType = (type) => {
    navigate({pathname:'/admin/booking-types/new', search:`?${createSearchParams({id: type._id})}`})
  }

  const viewBookings = (typeId) => {
    navigate({
      pathname: "/booking-slots",
      search: `?${createSearchParams({typeId: typeId})}`
    })
  }

  const deleteBookingType = (type) => {
    apiDelete('admin/bookings/types', type._id).then((response) => {
      if (response.status === 200) {
        toast(`Deleted '${type.name}'`, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Deleted_Type_${type._id}_Toast`
        })
        getBookingTypes()
      }else {
        toast.error(response.data.error, {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000
        })
      }
    })
  }

  const bookingTypesCols = (
    <React.Fragment>
      { bookingTypes.map((type, index, array) => (
        <div key={type._id} className='col p-2'>
          <div className='h-100 d-flex flex-column border border-mids-mutts rounded-3 p-2'>
            <div className='p-1'>
              <div className='display-8 mb-2'>{type.name}</div>
              <p>{type.description}</p>
              <p>Cost: { type.cost ? <React.Fragment>£{type.cost} per {type.per}</React.Fragment> : <React.Fragment>?</React.Fragment>}</p>
            </div>
            <div className='mt-auto'>
              <div className='mt-2 d-flex'>
                <button className='btn btn-outline-info' onClick={() => manageBookingType(type)} data-tooltip-place="bottom" data-tooltip-id="edit-tooltip" data-tooltip-content="Edit"><i className="fa-solid fa-edit"></i></button>
                <Link to={{pathname: '/booking-slots', search: `?${createSearchParams({typeId: type._id})}`}} state={{ from: { title: 'Booking Types' }}} className='btn btn-outline-mids-mutts flex-fill mx-2'>Booking Slots</Link>
                <button className='btn btn-outline-danger' onClick={() => deleteBookingType(type)} data-tooltip-place="bottom" data-tooltip-id="delete-tooltip" data-tooltip-content="Delete"><i className="fa-solid fa-trash"></i></button>
                <Tooltip id="delete-tooltip" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  )

  return (
    <div>
      <div>
        <button className='btn btn-sm btn-link' onClick={() => navigate('/admin')}><i className="fa-solid fa-arrow-left"></i> Admin</button>
      </div>
      <div className='d-sm-flex flex-wrap align-items-center'>
        <p className='py-2 m-0 display-6'>Booking Types</p>
        <div className='p-2 ms-auto'>
          <button onClick={() => navigate("/admin/booking-types/new")} className='btn btn-sm btn-outline-mids-mutts'>Add New Type</button>
        </div>
      </div>
      <div className='mb-4 container border-top border-mids-mutts pt-4 mt-4'>
      { (bookingTypes.length === 0 && !loading) &&
          <div className='text-center text-subtle display-8 pt-2'>No Results</div>
        }
        { loading ? 
        <div className='d-flex pt-2'>
          <ClipLoader className='mx-auto' color="orange" />
        </div>
         : 
        <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4'>
          {bookingTypesCols}
        </div> }
      </div>
      <div className='d-flex justify-content-center border-top border-mids-mutts pt-4 mt-4'>
        { bookingTypes.length > 0 &&
          paginator(page, pageCount, setPage)
        }
      </div>
      
      <div className='d-sm-flex flex-sm-wrap justify-content-sm-start justify-content-center'>
        <div className='py-3'>
          <div className='text-subtle text-center'>Results per page: {perPageOptions(3, 8, 16, perPage, setPerPage)}</div>
        </div>
      </div>
    </div>
  )
}

export default BookingTypes