import React, { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { apiGet, apiPost } from '../../helpers/NetworkHelper'
import { toast } from 'react-toastify'
import BookingTile from '../bookings/BookingTile'
import { ClipLoader } from "react-spinners"
import { Collapse } from 'react-bootstrap'

function BookingRequests({collapsable}) {

  const navigate = useNavigate()

  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(true || !collapsable)
  const [pages, setPages] = useState(0)

  const getRequests = useCallback(() => {
    setLoading(true)
    apiGet('admin/bookings/requests', {pageSize: 4, page: 0}).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setRequests(response.data.requests)
        setPages(response.data.pages)
      } else if (response.status === 401)
        navigate("/")
      else
        setRequests([])
    })
  }, [setRequests, navigate, setLoading])

  const acceptRequest = (request) => {
    apiPost("admin/bookings/requests/accept", {
      bookingId: request._id,
      specifyTime: false
    }).then((response) => {
      if (response.status === 401) {
        navigate("/")
      } else if (response.status === 200) {
        toast('Booking Accepted', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Booking_Accepted_${request._id}_Toast`
        })
        getRequests()
      }
    })
  }

  const rejectRequest = (request) => {
    apiPost("admin/bookings/requests/reject", {
      bookingId: request._id
    }).then((response) => {
      if (response.status === 401) {
        navigate("/")
      } else if (response.status === 200) {
        toast('Booking Rejected', {
          theme: 'dark',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          pauseOnFocusLoss: false,
          toastId: `Booking_Rejected_${request._id}_Toast`
        })
        getRequests()
      }
    })
  }

  const viewAllBookingRequests = () => {
    navigate({
      pathname: '/bookings',
      search: `?${createSearchParams({
        requestsOnly: true
      })}`
    })
  }

  useEffect(() => {
    getRequests()
  }, [getRequests])

  return (
    <div className='container py-2 bg-fore rounded-3'>
      <div className='d-flex flex-wrap align-items-center p-2'>
        <div className='display-8 me-2'>Pending Requests</div>
        { collapsable && 
          <button className='btn btn-dark border-0 text-mids-mutts' onClick={() => setShow(!show)}>
            <i className={show ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
          </button>
        }
      </div>
      <Collapse in={show}>
        <div>
          <div className='container'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 bg-fore rounded-3 p-2'>
              { (requests.length === 0 && !loading)  && <p className='col-xl-12 col-lg-12 col-md-12 col-12 display-8 p-2 mb-1 text-subtle text-center'>No results</p> }
              { loading ? 
                <div className='col-xl-12 col-lg-12 col-md-12 col-12 d-flex pt-2'>
                  <ClipLoader className='mx-auto' color="orange" />
                </div>
                : 
              requests.map((request) => (
                <BookingTile key={request._id} booking={request} acceptRequest={acceptRequest} rejectRequest={rejectRequest} showOwner={true} showPet={true} admin={true} />
              ))}
              { pages > 1 && 
                <div className='col-xl-12 col-lg-12 col-md-12 col-12 p-2 mt-1 d-flex'>
                  <div className='link-mids-mutts clickable ml-auto' onClick={viewAllBookingRequests}><u>View All Requests</u></div>
                </div> 
              }
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default BookingRequests