import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { apiGet, apiPost } from '../../helpers/NetworkHelper'
import { AuthContext } from '../../helpers/AuthContext'
import { Modal, Container, Row, Col } from 'react-bootstrap'

function ShopItems() {

  const {loggedInUser} = useContext(AuthContext)

  const state = {
    from: {
      title: "All Items"
    }
  }

  const [items, setItems] = useState([])
  const [pages, setPages] = useState(0)
  const [basket, setBasket] = useState(null)

  const addToBasket = (itemSizeId, quantity) => {
    let body = {
      itemSizeId,
      quantity: quantity
    }
    apiPost("shop/basket/item", body).then((response) => {
      if (response.status === 200)
        setBasket(response.data.basket)
    })
  }

  const getBasket = useCallback(() => {
    apiGet("shop/basket").then((response) => {
      console.log(response)
      if (response.status === 200) {
        setBasket(response.data.basket)
      }
    })
  }, [setBasket])

  useEffect(() => {
    getBasket()
  }, [getBasket])

  const setSelectedItemSize = (itemIndex, sizeId) => {
    const newItems = [...items]
    const item = {...items[itemIndex], selectedSize: sizeId}
    newItems[itemIndex] = item
    setItems([...newItems])
  }

  const setSelectedItemQuantity = (itemIndex, quantity) => {
    const newItems = [...items]
    const item = {...items[itemIndex], selectedQuantity: quantity}
    newItems[itemIndex] = item
    setItems([...newItems])
  }

  const itemsDisplay = (
    <div className='row'>
      { items.map((item, index) => (
        <div key={item._id} className='col-xl-3 col-lg-4 col-md-6 col-12 bg-fore rounded-3 p-3 d-flex flex-column gap-2' >
          <div className='d-flex'><img className='m-auto' src={item.imageKitUrl} alt={item.description} style={{height: "150px"}} /></div>
          <div className='d-flex flex-row flex-wrap gap-2 align-items-end'>
            <div className='lead'>{item.description}</div>
          </div>
          <select onChange={(e) => setSelectedItemSize(index, e.target.value)}  className='form-select bg-background text-light border-0'>
            { item.sizes.map((size) => (
              <option key={size._id} value={size._id}>{size.description} - £{size.price}</option>
            ))}
          </select>
          <div className='d-flex align-items-center gap-2 ps-1'>
            <div>x</div>
            <input className='form-control bg-background text-light border-0' defaultValue={1} onChange={(e) => setSelectedItemQuantity(index, e.target.value)} type="number" name={"quantity_" + item._id} id={"quantity_" + item._id} />
            <button onClick={() => addToBasket(item.selectedSize ?? item.sizes[0]._id, item.selectedQuantity ?? 1)} className='btn btn-sm btn-success'><i className="fa-solid fa-cart-plus"></i></button>
          </div>
        </div>
      )) }
    </div>
  )

  useEffect(() => {
    apiGet("shop/items", {pageSize: 8, page: 0}).then((response) => {
      if (response.status === 200) {
        setItems(response.data.items)
        setPages(response.data.pages)
      }
    })
  }, [setItems, setPages])

  const [showBasket, setShowBasket] = useState(false)
  const openBasket = () => setShowBasket(true)
  const closeBasket = () => setShowBasket(false)

  const getSubtotal = (item) => {
    return (item.quantity * item.shopItemSize.price)
  }

  const getBasketTotal = () => {
    let total = 0
    for (let i = 0; i < basket?.basketItems.length; i++) {
      let item = basket.basketItems[i]
      total = total + getSubtotal(item)
    }
    return total.toFixed(2)
  }

  const basketModal = (
    <Modal show={showBasket} onHide={closeBasket} size='lg' contentClassName='bg-background text-light montserrat'>
      <Modal.Header closeButton closeVariant='white' className='border-mids-mutts'>
        <Modal.Title id="contained-modal-title-vcenter">
          Your basket
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          { (!basket || basket.basketItems.length === 0) && 
            <div className='lead text-center text-subtle'>Empty</div>
          }
        { basket?.basketItems.map((item, index) => (
            <div key={item._id} className='border-mids-mutts bg-fore my-1 rounded-3 d-flex flex-wrap p-3'>
              <div className="d-flex flex-column">
                <img className='my-auto' src={item.shopItemSize.item.imageKitUrl} alt={item.shopItemSize.item.description} style={{width: "100px"}} />
              </div>
              <div className='p-2 d-flex flex-column'>
                <div>{item.shopItemSize.item.description} - {item.shopItemSize.description}</div>    
                <div className='d-flex align-items-center gap-2'>
                  Quantity: 
                  <input defaultValue={item.quantity} type="number" className='form-control bg-background text-light' style={{width: "3.5em"}} /> 
                  <button className='btn btn-sm btn-outline-success'>Update</button>
                </div>
              </div>
              <div className='ms-auto d-flex flex-column'>
                <div className='m-auto'>£{getSubtotal(item).toFixed(2)}</div>
              </div>
            </div>
        ))}
        </div>
      </Modal.Body>
      <Modal.Footer className='border-mids-mutts'>
        <div className='flex-fill d-flex flex-column gap-3'>
          <div className='ms-auto lead'>
            Total: £{getBasketTotal()}
          </div>
          <div className='ms-auto d-flex gap-3'>
            <button className='btn btn-success' onClick={closeBasket}>Checkout</button>
            <button className='btn btn-danger' onClick={closeBasket}>Close</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )

  return (
    <div className='d-flex flex-column gap-3'>
      <div className='d-flex flex-row gap-3 align-items-center flex-wrap'>
        <div className='display-7'>All Items</div>
        <div className='ms-auto d-flex gap-3 align-items-center'>
          <button onClick={openBasket} className='btn btn-outline-success'><i className="fa-solid fa-cart-shopping"></i> Basket {basket?.basketItems.length > 0 && `(${basket.basketItems.length})`}</button>
          { (loggedInUser && loggedInUser.role.isAdmin) && <Link state={state} to={{pathname: "/shop/item-form"}} className='btn btn-outline-info'>Add New</Link> }
        </div>
      </div>
      <div className='container'>
        {itemsDisplay}
      </div>
      {basketModal}
    </div>
  )
}

export default ShopItems