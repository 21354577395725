import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { apiGet, getCsrfToken } from '../helpers/NetworkHelper'
import BookingSlots from '../components/bookings/BookingSlots'
import BookingRequests from '../components/admin/BookingRequests'
import Users from '../components/admin/Users'
import { Collapse } from 'react-bootstrap'

function Admin() {
  const navigate = useNavigate()

  const [adminLevel, setAdminLevel] = useState(-1)
  const [show, setShow] = useState(true)

  const onAdminGet = useCallback((response) => {
    if (response.status === 200) {
      setAdminLevel(response.data.adminLevel)
    } else 
      navigate("/")
  }, [navigate])

  useEffect(() => {
    const getAdminLevel = () => {
      apiGet("admin/status").then((response) => {
        onAdminGet(response)
      })
    }
    getCsrfToken('Admin')
    getAdminLevel()
  }, [onAdminGet])

  const bookingsRow = (
    <React.Fragment>
      <div className='d-flex flex-wrap align-items-center pb-3'>
        <div className='display-6 me-2'>Bookings</div>
        <button className='btn btn-dark border-0 text-mids-mutts' onClick={() => setShow(!show)}>
          <i className={show ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
        </button>
      </div>
      <Collapse in={show}>
        <div>
          <div className='d-flex flex-column gap-3'>
            <div className='container'>
              <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 px-2'>
                <div className='col p-1'>
                  <Link className='btn btn-outline-mids-mutts btn-block' to={{pathname: '/admin/booking-slots/new'}} state={{from: {title: 'Admin'}}}>Create Booking Slot</Link>
                </div>
                <div className='col p-1'>
                  <Link className='btn btn-outline-mids-mutts btn-block' to={{pathname: '/booking-slots'}} state={{from: {title: 'Admin'}}}>Manage Booking Slots</Link>
                </div>
                <div className='col p-1'>
                  <Link className='btn btn-outline-mids-mutts btn-block' to={{pathname: '/admin/booking-types/new'}} state={{from: {title: 'Admin'}}}>Create Booking Type</Link>
                </div>
                <div className='col p-1'>
                  <Link className='btn btn-outline-mids-mutts btn-block' to={{pathname: '/admin/booking-types'}} state={{from: {title: 'Admin'}}}>Manage Booking Types</Link>
                </div>
              </div>
            </div>
            <BookingRequests />
            <BookingSlots admin={true} />
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  )

  const otherRow = (
    <React.Fragment>
      <div className='container'>
        <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 px-2'>
          <div className='col p-1'>
            <Link className='btn btn-outline-mids-mutts btn-block' to={{pathname: '/admin/site-settings'}} state={{from: {title: 'Admin'}}}>Site Settings</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
    { adminLevel >= 0 &&
      <div>
        <div>
          <Users />
        </div>
        <div className='border-top border-mids-mutts pt-4 mt-4'>
          {bookingsRow}
        </div>
        <div className='border-top border-mids-mutts pt-4 mt-4'>
          {otherRow}
        </div>
      </div>
    }
    </React.Fragment>
  )
}

export default Admin