import $ from "jquery";
import React from "react";
import { useContext, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { apiPost, getCsrfToken } from "../helpers/NetworkHelper";
import { toast } from "react-toastify";

import logo_min from "../assets/img/logo-min.png";

function Navigation() {
  const { loggedInUser, setLoggedInUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const logout = () => {
    collapse();
    apiPost("auth/logout").then((response) => {
      if (response.status === 200) {
        setLoggedInUser(null);
        navigate("/");
        toast(`Logged out`, {
          theme: "dark",
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          pauseOnFocusLoss: false,
        });
      }
    });
  };

  const collapse = () => {
    $(".navbar-collapse").removeClass("show");
  };

  const navbarGoTo = (dest, searchParams) => {
    collapse();
    navigate({
      pathname: dest,
      search: searchParams,
    });
  };

  useEffect(() => {
    getCsrfToken("Navigation");
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-fore sticky-top px-3">
      <div className="navbar-brand">
        <div className="clickable" onClick={() => navbarGoTo("/")}>
          <div className="d-flex align-items-center nav-item">
            <img
              height="50"
              className="d-inline-block"
              src={logo_min}
              alt="Logo"
            />
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <div className="nav-link" onClick={() => navbarGoTo("/")}>
                  Midlands Mutts
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <div className="collapse navbar-collapse" id="navbarText" >
              <ul className="navbar-nav ml-auto">
                { loggedInUser ? 
                <React.Fragment>
                  <li className="nav-item">
                    <button className="nav-link" onClick={() => navbarGoTo("/shop/items")}>Shop</button>
                  </li>
                  <li className="nav-item">
                    <button className='nav-link' onClick={() => navbarGoTo("/profile", `?${createSearchParams({username: loggedInUser.username})}`)}>@{loggedInUser.username}</button>
                  </li>
                  <li className="nav-item">
                    <button className='nav-link' onClick={logout}>Logout</button>
                  </li>
                </React.Fragment>
                :
                <React.Fragment>
                  <li className="nav-item">
                    <button className='nav-link' onClick={() => navbarGoTo("/login")}>Login</button> 
                  </li>
                  <li className="nav-item">
                    <button className='nav-link' onClick={() => navbarGoTo("/registration")}>Register</button>
                  </li>
                </React.Fragment>
                }
              </ul>
            </div> */}
    </nav>
  );
}

export default Navigation;
