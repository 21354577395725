import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../helpers/NetworkHelper'
import { useNavigate, useSearchParams, Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../helpers/AuthContext'
import { ClipLoader } from "react-spinners"

function Profile() {

  const {loggedInUser} = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()
  const profileUsername = searchParams.get("username")
  const [profileUser, setProfileUser] = useState(null)
  if (!profileUsername)
    navigate("/")
  const [pets, setPets] = useState([])
  const [petsLoading, setPetsLoading] = useState(true)

  useEffect(() => {
    const getUser = async (username) => {
      apiGet("users", {username: username}).then((response) => {
        if (response.status === 200) {
          setProfileUser(response.data)
          getPets(profileUsername)
        } else {
          navigate("/")
        }
      })
    }
    const getPets = async (owner) => {
      setPetsLoading(true)
      apiGet("pets", {
        username: owner
      }).then((response) => {
        setPetsLoading(false)
        if (response.status === 200)
          setPets(response.data)
        else
          setPets([])
      })
    }
    if (!profileUser || profileUser.username !== profileUsername) {
      getUser(profileUsername)
    } else {
      getPets(profileUsername)
    }
  }, [profileUsername, profileUser, navigate])

  const goToPet = (pet) => {
    navigate({
      pathname: "/pet",
      search: `?id=${pet._id}`
    })
  }

  const petsList = pets.map((pet) => (
    <div className='col-lg-2 col-md-3 col-12 p-2' key={pet._id}>
      <Link to={{pathname: "/pet", search: `?id=${pet._id}`}} state={{from: {title: "Pets"}}} className='text-white text-decoration-none'>
        <div className='clickable rounded-3 hoverable border-mids-mutts border bg-secondary p-0'>
          <div className='d-flex flex-row flex-md-column align-items-center'>
            <img className='img-responsive rounded-3 profile-pet-pic' alt='' src={pet.imageKitUrl} />
            <div className='fs-5 text-center p-3'>{pet.name}</div>
          </div>
        </div>
      </Link>
    </div>
  ))

  const addPet = () => {
    navigate('/new-pet')
  }

  const getTitle = () => {
    return ((loggedInUser && loggedInUser.username === profileUsername) ? "Your" : `${profileUser.firstname} ${profileUser.lastname}'s`) + " Profile"
  }

  return (
    <React.Fragment>
      { profileUser && 
        <React.Fragment>
          { location?.state?.from?.title && <div className='mb-2'>
            <button className='btn btn-sm btn-link' onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left pe-2"></i>{location?.state?.from?.title}</button>
          </div> }
          <div className='d-flex flex-row align-items-center p-3'>
            <div className='display-7'>{getTitle()}</div>
            { loggedInUser.role.isAdmin && <Link to={{pathname: "/admin/users/manage", search: `?username=${profileUser.username}`}} state={{from: {title: profileUser ? profileUser.username : "User"}}} className='ms-auto btn btn-outline-info'>Manage User</Link> }
          </div>
          <div className='rounded-4 bg-fore p-3'>
            <div className='d-flex align-items-center'>
              <div className='display-8'>Pets</div>
              { (loggedInUser && loggedInUser.username === profileUsername) && <button onClick={addPet} className='ms-auto btn btn-outline-mids-mutts'>Add Pet</button> }
            </div>
            <div className='row p-3'>
            { petsLoading ? 
              <div className='d-flex pt-2'>
                <ClipLoader className='mx-auto' color="orange" />
              </div>
              : 
              pets.length > 0 ?
                petsList
                :
                <div>
                  <div className='display-7 text-subtle text-center'>No pets yet</div>
                </div>
            }
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default Profile